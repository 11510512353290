import {
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  Typography,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { ChuTheme } from '../../../../@types/theme'
import fr from '../../../../assets/strings/fr'
import {
  displayPasswordUpdateModalAction,
  fetchUserInfosAction,
  loginAction,
} from '../../../../context/actions/user'
import { AppContext } from '../../../../context/app-context'
import { settings } from '../../../../theme/settings'
import { AuthFormLayout } from '../../../ui/AuthFormLayout'
import { SubmitButton } from '../../../ui/SubmitButton'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  forgottenPassword: {
    color: theme.palette.info.dark,
    textDecoration: 'underline',
    textAlign: 'left',
    padding: 10,
    fontSize: 12,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

const schema = Yup.object().shape({
  username: Yup.string()
    .required(fr.sign.login.username.required)
    .email(fr.sign.login.username.email),
  password: Yup.string().required(fr.sign.login.password.required),
})

export const SignIn = () => {
  const classes = useStyles()
  const {
    dispatch,
    user,
    authError: authErrorMessage,
    resetPasswordStatus,
    createPasswordStatus,
  } = useContext(AppContext)
  const browserHistory = useHistory()
  const [showError, setShowError] = useState(false)
  const [showResetPasswordSuccess, setShowResetPasswordSuccess] = useState(
    false,
  )
  const [showCreatePasswordSuccess, setShowCreatePasswordSuccess] = useState(
    false,
  )
  const [displayPassword, setDisplayPassword] = useState(false)

  const handleClickShowPassword = () => {
    setDisplayPassword((val) => !val)
  }

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  const goToForgottenPassword = () => {
    browserHistory.push('/forgotten-password')
  }
  useEffect(() => {
    user.state === 'error' && setShowError(true)
  }, [user.state])

  useEffect(() => {
    resetPasswordStatus === 'success' && setShowResetPasswordSuccess(true)
  }, [resetPasswordStatus])

  useEffect(() => {
    createPasswordStatus === 'success' && setShowCreatePasswordSuccess(true)
  }, [createPasswordStatus])

  return (
    <AuthFormLayout
      header={settings.strings.sign.login.intro}
      title={settings.strings.sign.login.title}
      errorTitle={fr.sign.login.errors.title}
      errorMessage={authErrorMessage}
      isError={showError}
      hideError={() => setShowError(false)}
      successTitle={
        resetPasswordStatus == 'success'
          ? fr.sign.forgottenPassword.reset.success.title
          : fr.sign.createPassword.success.title
      }
      successMessage={
        resetPasswordStatus == 'success'
          ? fr.sign.forgottenPassword.reset.success.message
          : fr.sign.createPassword.success.message
      }
      isSuccess={showResetPasswordSuccess || showCreatePasswordSuccess}
      hideSuccess={() => {
        setShowResetPasswordSuccess(false)
        setShowCreatePasswordSuccess(false)
      }}
    >
      <Formik<LoginFormValues>
        initialValues={{ username: '', password: '' }}
        validationSchema={schema}
        onSubmit={async ({ username, password }, { setSubmitting }) => {
          try {
            await dispatch(loginAction({ username, password }))
            await dispatch(fetchUserInfosAction())
            await dispatch(displayPasswordUpdateModalAction())
            browserHistory.push('/')
          } catch (error) {
            setShowError(true)
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormControl variant="filled">
              <InputLabel htmlFor="filled-adornment-username">
                {settings.strings.sign.login.username.label}
              </InputLabel>
              <FilledInput
                placeholder={settings.strings.sign.login.username.label}
                value={values.username}
                error={!!errors.username && touched.username}
                name="username"
                onChange={(e) => handleChange(e)}
                onBlur={handleBlur}
                disabled={isSubmitting}
                type="email"
              />
              {errors.username && (
                <FormHelperText>{errors.username}</FormHelperText>
              )}
            </FormControl>

            <FormControl variant="filled">
              <InputLabel htmlFor="filled-adornment-password">
                {settings.strings.sign.login.password.label}
              </InputLabel>
              <FilledInput
                placeholder={settings.strings.sign.login.password.label}
                value={values.password}
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                type={displayPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      color="secondary"
                    >
                      {displayPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.password && (
                <FormHelperText>{errors.password}</FormHelperText>
              )}
            </FormControl>

            <Typography
              component="p"
              className={classes.forgottenPassword}
              onClick={goToForgottenPassword}
            >
              {settings.strings.sign.login.forgottenPassword.label}
            </Typography>

            <SubmitButton
              title={settings.strings.sign.login.button}
              disabled={
                !dirty || !!errors.username || !!errors.password || isSubmitting
              }
            />
          </form>
        )}
      </Formik>
    </AuthFormLayout>
  )
}
