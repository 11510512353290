import React, { useContext, useEffect } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import fr from '../assets/strings/fr'
import { logoutAction } from '../context/actions/user'
import { addNotificationAction, AppContext } from '../context/app-context'
import clientStorageFields from '../utils/clientStorageFields'
import { isRoleAmong } from '../utils/isRoleAmong'

interface Props extends RouteProps {
  component: React.ComponentType<any>
  roles?: Array<UserRole>
}

/**
 * Route permettant d'avoir :
 *  - Une restriction d'accès selon le rôle
 */
export const CustomRoute: React.FC<Props> = ({
  component: Component,
  roles,
  ...rest
}) => {
  const { user, dispatch } = useContext(AppContext)

  // Si l'utilisateur est connecté mais sans token, le déconnecte
  useEffect(() => {
    const token = localStorage.getItem(clientStorageFields.authToken)
    const refreshToken = localStorage.getItem(clientStorageFields.refreshToken)
    const logoutIfNoToken = async () => {
      if (user.data && !token && !refreshToken) {
        await dispatch(logoutAction())
        await dispatch(
          addNotificationAction(
            'single-session',
            'error',
            fr.sign.login.errors.singleSession,
            true,
          ),
        )
      }
    }
    logoutIfNoToken()
  }, [dispatch])

  // Si le rôle n'est pas autorisé, redirige vers la page d'accueil
  if (roles && user.data?.role && !isRoleAmong(user.data.role, roles)) {
    return <Redirect to="/" />
  }

  // Affiche le composant
  return (
    <Route {...rest} render={(matchProps) => <Component {...matchProps} />} />
  )
}
