import { makeStyles } from '@material-ui/core'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import SvgIcon from '@material-ui/core/SvgIcon'
import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { ChuTheme } from '../../@types/theme'
import { ReactComponent as Home } from '../../assets/icons/tabbar_icon_accueil.svg'
import { ReactComponent as Calendar } from '../../assets/icons/tabbar_icon_agenda.svg'
import { ReactComponent as Contact } from '../../assets/icons/tabbar_icon_contact.svg'
import { ReactComponent as Infos } from '../../assets/icons/tabbar_icon_information.svg'
import { ReactComponent as Status } from '../../assets/icons/tabbar_icon_statut.svg'
import { AppContext } from '../../context/app-context'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  navbar: {
    height: '60px',
    backgroundColor: theme.palette.navbarColor,
  },
  root: {
    color: theme.palette.info.main,
    '&$selected': {
      color: theme.palette.primary.light,
      fill: theme.palette.primary.light,
    },
    minWidth: '40px',
    margin: '0 auto',
  },
  selected: {
    color: theme.palette.primary.light,
    '&$selected': {
      color: theme.palette.primary.light,
      fill: theme.palette.primary.light,
    },
  },
}))

interface BottomNavigationMaterialProps {
  Link: any
}

export const BottomNavigationBar: React.FC<BottomNavigationMaterialProps> = ({
  Link,
}) => {
  const classes = useStyles()
  const location = useLocation()
  const { user } = useContext(AppContext)

  return (
    <BottomNavigation
      className={classes.navbar}
      showLabels
      value={location.pathname}
    >
      <BottomNavigationAction
        component={Link}
        to="/"
        label="Accueil"
        value={'/'}
        icon={<SvgIcon component={Home} viewBox="0 0 26 26" fontSize="large" />}
        classes={{ root: classes.root, selected: classes.selected }}
      />
      <BottomNavigationAction
        component={Link}
        to="/profile"
        label="Profil"
        value={'/profile'}
        icon={
          <SvgIcon component={Status} viewBox="0 0 25 25" fontSize="large" />
        }
        classes={{ root: classes.root, selected: classes.selected }}
      />
      {user.data?.role !== 'inspector' && [
        <BottomNavigationAction
          component={Link}
          key="calendar"
          to="/calendar"
          label="Agenda"
          value={'/calendar'}
          icon={
            <SvgIcon
              component={Calendar}
              viewBox="0 0 28 28.964"
              fontSize="large"
            />
          }
          classes={{ root: classes.root, selected: classes.selected }}
        />,
        <BottomNavigationAction
          component={Link}
          key="infos"
          to="/infos"
          label="Informations"
          value={'/infos'}
          icon={
            <SvgIcon
              component={Infos}
              viewBox="0 0 30 23.26"
              fontSize="large"
            />
          }
          classes={{ root: classes.root, selected: classes.selected }}
        />,
      ]}
      <BottomNavigationAction
        component={Link}
        to="/contact"
        label="Contact"
        value={'/contact'}
        icon={
          <SvgIcon
            component={Contact}
            viewBox="0 0 25 27.191"
            fontSize="large"
          />
        }
        classes={{ root: classes.root, selected: classes.selected }}
      />
    </BottomNavigation>
  )
}
