import styled from '@emotion/styled'
import {
  Box,
  Container,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import React, { useContext, useState } from 'react'
import { ChuTheme } from '../../../@types/theme'
import { takeAppointmentAction } from '../../../context/actions/message'
import { AppContext } from '../../../context/app-context'
import browserHistory from '../../../router/history'
import { settings } from '../../../theme/settings'
import { Footer } from '../../Layout/Footer'
import { DialogMessage } from '../../ui/DialogMessage'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      overflow: 'scroll',
    },
    color: theme.palette.white,
    [theme.breakpoints.up('md')]: {
      background: theme.palette.linearBackground,
      paddingBottom: theme.spacing(7),
    },
  },
  title: {
    color: theme.palette.white,
    fontSize: '16px',
    fontWeight: 500,
    padding: '50px 30px 30px 30px',
  },
  buttonText: {
    padding: '15px',
    color: theme.palette.white,
    textTransform: 'uppercase',
    fontSize: '15px',
    fontWeight: 500,
    textAlign: 'left',
  },
  gridButton: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
  contactButtonWrapper: {
    position: 'relative',
    boxSizing: 'content-box',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 345,
    },
  },
}))

interface Props {
  text: string
  img: string
  color: string
  isDisabled: boolean
  onClick?: () => void
}

interface ButtonProps {
  backgroundColor: string
}

const StyledButton = styled.button<ButtonProps>(
  {
    minHeight: '160px',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
    width: '100%',
    '&:disabled': {
      filter: ' brightness(40%)',
    },
  },

  (props) => ({ backgroundColor: props.backgroundColor }),
)

const ComingSoon = styled.div({
  position: 'absolute',
  top: ' 15px',
  right: 0,
  backgroundColor: `${settings.theme.palette.primary.light}`,
  color: `${settings.theme.palette.white}`,
  padding: '5px 10px',
  textTransform: 'uppercase',
  borderRadius: '4px 0 0 4px',
  zIndex: 100,
  fontSize: '11px',
  fontWeight: 600,
})

const ContactButton = (props: Props) => {
  const classes = useStyles()
  return (
    <Box className={classes.contactButtonWrapper}>
      {props.isDisabled && <ComingSoon>Bientôt disponible</ComingSoon>}
      <StyledButton
        backgroundColor={props.color}
        disabled={props.isDisabled}
        onClick={props.onClick}
      >
        <img src={props.img} alt="logo button" />
        <Typography component="p" className={classes.buttonText}>
          {props.text}
        </Typography>
      </StyledButton>
    </Box>
  )
}

export const ContactHomepage = () => {
  const strings = settings.strings.homepage
  const { dispatch, takeAppointmentStatus } = useContext(AppContext)
  const classes = useStyles()
  const theme = useTheme()

  const [isConfirmAppointmentOpen, setIsConfirmAppointmentOpen] = useState(
    false,
  )

  const handleTakeAppointmentButtonClick = async () => {
    setIsConfirmAppointmentOpen(true)
  }

  const handleTakeAppointmentConfirm = async () => {
    await dispatch(takeAppointmentAction())
    setIsConfirmAppointmentOpen(false)
  }
  const handleTakeAppointmentCancel = async () => {
    setIsConfirmAppointmentOpen(false)
  }

  const goToReportBugPage = () => {
    browserHistory.push('/contact/bug-report')
  }

  return (
    <>
      <Container className={classes.root} maxWidth="md">
        <Typography component="h2" className={classes.title}>
          {settings.strings.contactPage.title}
        </Typography>

        <Grid container>
          <Grid item xs={12} md={6} className={classes.gridButton}>
            <ContactButton
              img={settings.images.contactAppointment}
              text={settings.strings.contactPage.buttons.appointment}
              color={theme.palette.secondary.main}
              isDisabled={false}
              onClick={handleTakeAppointmentButtonClick}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridButton}>
            <ContactButton
              img={settings.images.contactHealth}
              text={settings.strings.contactPage.buttons.healthConcerns}
              color={theme.palette.warning.light}
              isDisabled={true}
            />
          </Grid>
          <Grid item xs={12} className={classes.gridButton}>
            <ContactButton
              img={settings.images.contactApp}
              text={settings.strings.contactPage.buttons.appBugRequest}
              color={theme.palette.primary.main}
              isDisabled={false}
              onClick={goToReportBugPage}
            />
          </Grid>
        </Grid>
        <Footer />
      </Container>

      {/* CONFIRMATION DE DEMANDE DE TELE-CONSULTATION */}
      <DialogMessage
        open={isConfirmAppointmentOpen}
        title={strings.appointmentConfirmDialogTitle}
        content={strings.appointmentConfirmDialogContent}
        cancel={strings.appointmentConfirmDialogCancel}
        confirm={strings.appointmentConfirmDialogConfirm}
        onConfirm={handleTakeAppointmentConfirm}
        onClose={handleTakeAppointmentCancel}
        disabled={takeAppointmentStatus === 'loading'}
      />
    </>
  )
}
