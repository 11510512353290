import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { ChuTheme } from '../../@types/theme'
import i18n from '../../assets/strings'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  link: {
    color: theme.palette.white,
    textDecoration: 'none',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  linkText: {
    fontSize: 12,
    borderBottom: `1px solid transparent`,
    '&:hover': {
      borderBottom: `1px solid ${theme.palette.white}`,
    },
  },
}))

export const Footer = () => {
  const classes = useStyles()
  const strings = i18n.footer

  return (
    <Box className={classes.root}>
      <Link className={classes.link} to="/">
        <Typography className={classes.linkText}>{strings.homeLink}</Typography>
      </Link>
      <Link className={classes.link} to="/legal-notice">
        <Typography className={classes.linkText}>
          {strings.legalNoticeLink}
        </Typography>
      </Link>
    </Box>
  )
}
