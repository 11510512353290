import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box/Box'
import React from 'react'
import { ChuTheme } from '../../../@types/theme'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {},
  dotContainer: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'center',
  },
  dots: {
    height: 18,
    width: 18,
    borderRadius: 6,
    zIndex: 997,
  },
  dot: {
    backgroundColor: theme.palette.tertiaryColorDark,
    height: 10,
    width: 10,
    borderRadius: 6,
    margin: 3,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    height: 10,
    width: 10,
    borderRadius: 6,
    margin: 3,
  },
}))

export const Pagination = ({
  dots,
  index,
  onChangeIndex,
}: {
  dots: number
  index: number
  onChangeIndex: (index: number) => void
}) => {
  const classes = useStyles()
  const handleClick = (_: any, index: number) => {
    onChangeIndex(index)
  }

  const children: any = []

  for (let i = 0; i < dots; i += 1) {
    children.push(
      <PaginationDot
        key={i}
        index={i}
        active={i === index}
        onClick={handleClick}
      />,
    )
  }
  return <Box className={classes.dotContainer}>{children}</Box>
}

const PaginationDot = ({
  active,
  index,
  onClick,
}: {
  active: boolean
  index: number
  onClick: (event: any, index: number) => void
}) => {
  const classes = useStyles()
  const handleClick = (event: any) => {
    onClick(event, index)
  }

  let styleDot

  if (active) {
    styleDot = classes.active
  } else {
    styleDot = classes.dot
  }

  return (
    <div className={classes.dots} onClick={handleClick}>
      <Box className={styleDot} />
    </div>
  )
}
