import { SurveyStateEnum } from '../context/actions/survey'

export function areAllOtherSurveysCompleted(
  arrayOfSurveys: Array<Survey>,
): boolean {
  const numberOfCompletedSurvey = arrayOfSurveys.reduce<number>(
    (completedSurveys, survey) => {
      if (survey.state === SurveyStateEnum.completed) {
        completedSurveys += 1
      }
      return completedSurveys
    },
    0,
  )
  return arrayOfSurveys.length === numberOfCompletedSurvey + 1 ? true : false
}
