import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { ChuTheme } from '../../../../@types/theme'
import { settings } from '../../../../theme/settings'
import { TextInput } from '../../../ui/TextInput'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    padding: 0,
    position: 'relative',
    overflowY: 'hidden',
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    height: '90px',
    padding: '15px',
  },
  button: {
    margin: '5px',
  },
  question: {
    fontSize: '18px',
    textAlign: 'center',
    fontWeight: 500,
    color: theme.palette.white,
    lineHeight: 1.2,
    padding: '20px',
    backgroundColor: theme.palette.primary.main,
  },
  subquestion: {
    fontSize: '16px',
    textAlign: 'center',
    color: theme.palette.info.dark,
    lineHeight: 1.2,
  },
  formLabel: {
    minHeight: '40px',
    backgroundColor: theme.palette.background.paper,
    borderBottom: `solid 1px ${theme.palette.lightAccentColor}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
  },
  radioButton: {
    color: theme.palette.success.main,
  },
}))

interface QASingleChoiceProps {
  question: Question
  handleChange: (arrayOfOneAnswer: Array<any>) => void
  nextButtonIsDisabled: boolean
  onNextButtonClick: () => void
  nextButtonLabel: string
}

export const QASingleChoice = ({
  question,
  handleChange,
  nextButtonIsDisabled,
  onNextButtonClick,
  nextButtonLabel,
}: QASingleChoiceProps) => {
  const classes = useStyles()
  const [text, setText] = useState<string>('')

  // Ref pour le scroll automatique au clic sur une réponse
  const topOfAnswersRef = useRef<any>(null)
  const buttonsRef = useRef<any>(null)

  // FUNCTIONS
  const scrollOnClick = () => {
    buttonsRef.current &&
      buttonsRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const handleNextButtonClick = () => {
    setText('')
    onNextButtonClick()
  }

  // DATA
  const nameSelected = question.userAnswers[0]?.name
  const isTextAnswerSelected =
    question.answers.find((answer) => answer.name === nameSelected)?.value ===
    ''
  const selectedValue =
    (question && question.userAnswers[0] && question.userAnswers[0].value) ||
    false
  const isNextButtonDisabled =
    nextButtonIsDisabled ||
    !question ||
    question.userAnswers.length === 0 ||
    (isTextAnswerSelected && text === '')

  // RENDER
  return (
    <Container className={classes.root}>
      {/* TITRE */}
      <Typography component="h3" className={classes.question}>
        {(question.isSubQuestion && question.instructions) || question.name}
      </Typography>

      {/* RADIO BUTTONS */}
      <FormControl component="fieldset" ref={topOfAnswersRef}>
        <RadioGroup aria-label="question" name="question" value={selectedValue}>
          {question.isSubQuestion && (
            <FormLabel component="legend" className={classes.formLabel}>
              <Typography className={classes.subquestion}>
                {question.name}
              </Typography>
            </FormLabel>
          )}

          {question.answers.map((answer: Answer) => {
            const isSelected = answer.name === question?.userAnswers?.[0]?.name

            return (
              <FormControlLabel
                key={answer.value + answer.name}
                value={answer.value}
                checked={isSelected}
                control={<Radio color="default" />}
                label={
                  answer.value === '' ? (
                    <TextInput
                      label={answer.name}
                      value={text}
                      name={answer.name}
                      onFocus={() => {
                        scrollOnClick()
                        handleChange([{ name: answer.name, value: text }])
                      }}
                      onChange={(e) => {
                        handleChange([
                          { name: e.target.name, value: e.target.value },
                        ])
                        setText(e.target.value)
                      }}
                    />
                  ) : (
                    answer.name
                  )
                }
                name={answer.name}
                onClick={() => {
                  scrollOnClick()
                  handleChange([{ name: answer.name, value: answer.value }])
                }}
                style={
                  isSelected
                    ? {
                        backgroundColor: settings.theme.palette.success.light,
                        color: settings.theme.palette.success.main,
                        fontWeight: 500,
                      }
                    : {}
                }
              />
            )
          })}
        </RadioGroup>

        {/* NEXT BUTTON */}
        <div ref={buttonsRef}>
          <Box className={classes.inputWrapper}>
            <Button
              disabled={isNextButtonDisabled}
              onClick={handleNextButtonClick}
              variant="contained"
              color="secondary"
              size="large"
              className={classes.button}
            >
              {nextButtonLabel}
            </Button>
          </Box>
        </div>
      </FormControl>
    </Container>
  )
}
