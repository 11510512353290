import {
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
} from '@material-ui/core'
import { Formik } from 'formik'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import fr from '../../../assets/strings/fr'
import { forgottenPasswordRequest } from '../../../services/auth.service'
import { settings } from '../../../theme/settings'
import { AuthFormLayout } from '../../ui/AuthFormLayout'
import { PreviousButton } from '../../ui/PreviousButton'
import { SubmitButton } from '../../ui/SubmitButton'

const schema = Yup.object().shape({
  email: Yup.string()
    .required(fr.sign.forgottenPassword.request.email.required)
    .email(fr.sign.forgottenPassword.request.email.email),
})

/**
 * Formulaire de requête de nouveau mot de passe (champ: email)
 */
export const ForgottenPasswordRequest = () => {
  const browserHistory = useHistory()
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const goBack = () => {
    browserHistory.push('/')
  }

  return (
    <AuthFormLayout
      header={settings.strings.sign.forgottenPassword.intro}
      title={settings.strings.sign.forgottenPassword.title}
      errorTitle={fr.sign.forgottenPassword.request.errors.title}
      errorMessage={fr.sign.forgottenPassword.request.errors.message}
      isError={showError}
      hideError={() => setShowError(false)}
      successTitle={fr.sign.forgottenPassword.request.success.title}
      successMessage={fr.sign.forgottenPassword.request.success.message}
      isSuccess={showSuccess}
      hideSuccess={() => setShowSuccess(false)}
    >
      <Formik<{ email: string }>
        initialValues={{ email: '' }}
        validationSchema={schema}
        onSubmit={async ({ email }, { setSubmitting }) => {
          try {
            await forgottenPasswordRequest({ email })
            setShowSuccess(true)
          } catch (error) {
            setShowError(true)
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormControl variant="filled">
              <InputLabel htmlFor="filled-adornment-username">
                {settings.strings.sign.forgottenPassword.request.email.label}
              </InputLabel>
              <FilledInput
                placeholder={
                  settings.strings.sign.forgottenPassword.request.email.label
                }
                value={values.email}
                error={!!errors.email && touched.email}
                name="email"
                onChange={(e) => handleChange(e)}
                onBlur={handleBlur}
                disabled={isSubmitting}
                type="text"
              />
              {errors.email && <FormHelperText>{errors.email}</FormHelperText>}
            </FormControl>

            <PreviousButton
              title={settings.strings.sign.forgottenPassword.request.return}
              onClick={goBack}
              marginRight
            />
            <SubmitButton
              title={settings.strings.sign.forgottenPassword.request.button}
              disabled={!dirty || !!errors.email || isSubmitting}
            />
          </form>
        )}
      </Formik>
    </AuthFormLayout>
  )
}
