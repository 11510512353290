export default {
  logo: '/images/Chut/png/logo_co-vie-apres.png',
  homepage: '/images/Chut/svg/HP_Rappel_rdv_Personnages.svg',
  formHomepage: '/images/Chut/svg/Questionnaire_HP_Personnages.svg',
  confirmForm: '/images/Chut/svg/POPIN_Confirmation_Personnages.svg',
  warning: '/images/Chut/png/icon_warning.png',
  inProgress: '/images/commons/TEMPORARY_ConstructionPage_Image.svg',
  profilHospital: '/images/commons/Profil_hopital_icon.svg',
  profilHouse: '/images/commons/Profil_house_icon.svg',
  moodPeople: '/images/commons/mood_people.svg',
  moodGood: '/images/commons/mood_good.svg',
  moodMedium: '/images/commons/mood_medium.svg',
  moodBad: '/images/commons/mood_bad.svg',
  iconCheck: '/images/commons/icon_check.svg',
  contactHealth: '/images/Chut/svg/CONTACT_MotifContact_Chat.svg',
  contactApp: 'images/Chut/svg/CONTACT_MotifContact_PbOUTIL.svg',
  contactAppointment:
    'images/Chut/svg/CONTACT_MotifContact_Teleconsultation.svg',
  stepOne: 'images/onBoarding/ON_BOARDING_Ecran1_Illustration.png',
  stepTwo: 'images/onBoarding/ON_BOARDING_Ecran2_Illustration.png',
  stepThree: 'images/onBoarding/ON_BOARDING_Ecran3_Illustration.png',
  stepFour: 'images/onBoarding/ON_BOARDING_Ecran4_Illustration.png',
  calendarHouseIcon: 'images/commons/calendar_house_icon.svg',
  calendar1Month: 'images/commons/calendar_month_1.png',
  calendar3Months: 'images/commons/calendar_month_3.png',
  calendar6Months: 'images/commons/calendar_month_6.png',
  calendar1Year: 'images/commons/calendar_year_1.png',
  nextAppointmentIcon: 'images/commons/next_appointment_icon.svg',
}
