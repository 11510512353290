import {
  Box,
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  Typography,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { ChuTheme } from '../../../@types/theme'
import fr from '../../../assets/strings/fr'
import { createPasswordAction } from '../../../context/actions/user'
import { AppContext } from '../../../context/app-context'
import { checkPasswordToken } from '../../../services/auth.service'
import { settings } from '../../../theme/settings'
import { AuthFormLayout } from '../../ui/AuthFormLayout'
import { SubmitButton } from '../../ui/SubmitButton'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  loadingDescriptionContainer: {
    padding: theme.spacing(4),
  },
  loadingDescription: {
    fontSize: 14,
  },
}))

const schema = Yup.object().shape({
  password: Yup.string().required(
    fr.sign.forgottenPassword.reset.password.required,
  ),
  confirmPassword: Yup.string()
    .required(fr.sign.forgottenPassword.reset.confirmPassword.required)
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        fr.sign.forgottenPassword.reset.notTheSamePassword,
      ),
    }),
})

export const CreatePassword = () => {
  const browserHistory = useHistory()
  const { token } = useParams<{ token?: string }>()
  const classes = useStyles()

  const { dispatch } = useContext(AppContext)

  const [loading, setLoading] = useState(true)
  const [isTokenValid, setIsTokenValid] = useState(false)
  const [showError, setShowError] = useState(false)
  const [displayPassword, setDisplayPassword] = useState(false)
  const [displayConfirmPassword, setDisplayConfirmPassword] = useState(false)

  useEffect(() => {
    const checkIfTokenIsValid = async (passwordToken: string) => {
      const { isTokenValid } = await checkPasswordToken(passwordToken)
      setIsTokenValid(isTokenValid)
      setLoading(false)
    }

    if (token) {
      checkIfTokenIsValid(token)
    }
  }, [token])

  const handleClickShowPassword = () => {
    setDisplayPassword((val) => !val)
  }
  const handleClickShowConfirmPassword = () => {
    setDisplayConfirmPassword((val) => !val)
  }

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  if (loading) {
    return (
      <AuthFormLayout
        header={settings.strings.sign.forgottenPassword.intro}
        title={fr.sign.createPassword.checkingToken.title}
      >
        <Box className={classes.loadingDescriptionContainer}>
          <Typography className={classes.loadingDescription}>
            {fr.sign.createPassword.checkingToken.description}
          </Typography>
        </Box>
      </AuthFormLayout>
    )
  }

  if (!isTokenValid) {
    return (
      <AuthFormLayout
        header={settings.strings.sign.forgottenPassword.intro}
        title={fr.sign.createPassword.invalidToken.title}
      >
        <Box className={classes.loadingDescriptionContainer}>
          <Typography className={classes.loadingDescription}>
            {fr.sign.createPassword.invalidToken.description}
          </Typography>
        </Box>
      </AuthFormLayout>
    )
  }

  return (
    <AuthFormLayout
      header={settings.strings.sign.forgottenPassword.intro}
      title={settings.strings.sign.createPassword.title}
      subtitle={fr.sign.forgottenPassword.subtitle}
      errorTitle={fr.sign.createPassword.errors.title}
      errorMessage={fr.sign.createPassword.errors.message}
      isError={showError}
      hideError={() => setShowError(false)}
    >
      <Formik<{ password: string; confirmPassword: string }>
        initialValues={{ password: '', confirmPassword: '' }}
        validationSchema={schema}
        onSubmit={async ({ password, confirmPassword }, { setSubmitting }) => {
          if (password !== confirmPassword) {
            setShowError(true)
            return
          }

          try {
            const createPasswordInputs: CreatePasswordInputs = {
              password,
              passwordToken: token || '',
            }
            await dispatch(createPasswordAction(createPasswordInputs))
            browserHistory.push('/')
          } catch (error) {
            setShowError(true)
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {({
          values,
          errors,
          dirty,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormControl variant="filled">
              <InputLabel htmlFor="filled-adornment-password">
                {settings.strings.sign.forgottenPassword.reset.password.label}
              </InputLabel>
              <FilledInput
                placeholder={
                  settings.strings.sign.forgottenPassword.reset.password.label
                }
                value={values.password}
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                type={displayPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      color="secondary"
                    >
                      {displayPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.password && (
                <FormHelperText>{errors.password}</FormHelperText>
              )}
            </FormControl>

            <FormControl variant="filled">
              <InputLabel htmlFor="filled-adornment-password">
                {
                  settings.strings.sign.forgottenPassword.reset.confirmPassword
                    .label
                }
              </InputLabel>
              <FilledInput
                placeholder={
                  settings.strings.sign.forgottenPassword.reset.confirmPassword
                    .label
                }
                value={values.confirmPassword}
                name="confirmPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                type={displayConfirmPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      color="secondary"
                    >
                      {displayConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.confirmPassword && (
                <FormHelperText>{errors.confirmPassword}</FormHelperText>
              )}
            </FormControl>

            <SubmitButton
              title={settings.strings.sign.createPassword.confirm}
              disabled={
                !dirty ||
                !!errors.password ||
                !!errors.confirmPassword ||
                isSubmitting
              }
            />
          </form>
        )}
      </Formik>
    </AuthFormLayout>
  )
}
