import { Box, makeStyles, Typography } from '@material-ui/core'
import { differenceInDays, format } from 'date-fns'
import { fr } from 'date-fns/locale'
import React from 'react'
import { ChuTheme } from '../../../@types/theme'
import i18n from '../../../assets/strings'
import { settings } from '../../../theme/settings'

interface Props {
  patient: Patient | undefined
}

/**
 * Header appelé dans <Home /> affichant le nombre de jours depuis lequel le patient est sorti de l'hôpital
 * ainsi qu'un texte l'incitant à utiliser l'outil
 */
const useStyles = makeStyles<ChuTheme>((theme) => ({
  headerBar: {
    padding: '0 16px 24px 16px',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      overflow: 'hidden',
      zIndex: 2,
      background: 'linear-gradient(#0c398d, #0b49a9)',
      boxShadow: '0 1px 4px rgba(0, 0, 0, 0.3)',
    },
  },
  welcomeContainer: {
    marginBottom: 12,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  welcome: {
    fontSize: '14px',
    fontStyle: 'italic',
  },
  lastConnection: {
    fontSize: '12px',
    fontStyle: 'italic',
  },
  headerDaysSinceOutOfHospital: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
  },
  highlightedText: {
    background: theme.palette.secondary.main,
    padding: 4,
    fontWeight: theme.typography.fontWeightMedium,
  },
  headerWeNeedYou: {
    textAlign: 'center',
    fontSize: 12,
  },
}))

export const HomeHeader: React.FC<Props> = ({ patient }) => {
  const classes = useStyles()

  const daysSinceOutOfHospital = patient?.outHospitalDate
    ? differenceInDays(new Date(), new Date(patient?.outHospitalDate))
    : ''

  const lastConnection = patient?.lastConnections
    ? patient.lastConnections[patient.lastConnections.length - 2] // On ne veut pas la date de la connexion actuelle (dernière), mais de la connexion précédente (avant-dernière)
    : undefined
  const lastConnectionString = lastConnection
    ? format(
        lastConnection,
        settings.strings.homepage.lastConnection.dateFormat,
        { locale: fr },
      )
    : undefined

  return (
    <Box className={classes.headerBar}>
      <Box className={classes.welcomeContainer}>
        <Typography className={classes.welcome}>
          {settings.strings.homepage.welcome}{' '}
          {patient?.firstName || patient?.codeId}
        </Typography>

        {lastConnectionString && (
          <Typography className={classes.lastConnection}>
            {settings.strings.homepage.lastConnection.lastConnection}{' '}
            {lastConnectionString}
          </Typography>
        )}
      </Box>

      {patient?.outHospitalDate && (
        <>
          <Typography className={classes.headerDaysSinceOutOfHospital}>
            {i18n.home.header.daysSinceOutOfHospitalSince}{' '}
            <span className={classes.highlightedText}>
              {daysSinceOutOfHospital}{' '}
              {i18n.home.header.daysSinceOutOfHospitalDays}
            </span>
          </Typography>
          <Typography className={classes.headerWeNeedYou}>
            {i18n.home.header.weNeedYou}
          </Typography>
        </>
      )}
    </Box>
  )
}
