import clientStorageFields from '../utils/clientStorageFields'
import Api from './api'

export const saveAuthToken = (name: string, token: string): void => {
  localStorage.setItem(name, token)
}

export const removeAuthToken = (name: string) => {
  localStorage.removeItem(name)
}

export async function loginUser(userAuthInputs: UserAuthInputs) {
  try {
    const response = await Api.post<UserAccessToken>(
      '/auth/login',
      userAuthInputs,
    )

    if (response.data.accessToken) {
      saveAuthToken(clientStorageFields.authToken, response.data.accessToken)
    }
    if (response.data.refreshToken) {
      saveAuthToken(
        clientStorageFields.refreshToken,
        response.data.refreshToken,
      )
    }
  } catch (error) {
    throw new Error('Could not login')
  }
}

export async function getUserInfos(): Promise<ApiUser> {
  try {
    const response = await Api.get('/users/me')
    return response.data
  } catch (error) {
    throw new Error('Could not get user infos')
  }
}

export async function updatefirstConnection(): Promise<void> {
  try {
    await Api.put('/users/me/first-connection')
  } catch (error) {
    throw new Error('Could not update first connection infos')
  }
}

export async function getDoctorsInfo(): Promise<ApiDoctor[]> {
  try {
    const response = await Api.get('/users/me/doctors')
    return response.data
  } catch (error) {
    throw new Error('Could not get doctors infos')
  }
}
export async function getUserInfosById(userId: string): Promise<ApiUser> {
  try {
    const response = await Api.get(`/users/${userId}`)
    return response.data
  } catch (error) {
    throw new Error('Could not get user infos by id')
  }
}

export async function forgottenPasswordRequest(email: {
  email: string
}): Promise<ApiSuccess> {
  try {
    await Api.post('/auth/forgotten-password-request', {
      email: email.email,
    })
    return { status: 'success' }
  } catch (error) {
    throw error
  }
}
export async function forgottenPasswordReset(
  resetPasswordInputs: ResetPasswordInputs,
): Promise<ApiSuccess> {
  try {
    await Api.post('/auth/forgotten-password-reset', resetPasswordInputs)
    return { status: 'success' }
  } catch (error) {
    throw error
  }
}
export async function checkPasswordToken(passwordToken: string) {
  try {
    const response = await Api.get<CheckPasswordTokenResponse>(
      `/auth/check-password-token/${passwordToken}`,
    )
    return response.data
  } catch (error) {
    throw error
  }
}
export async function createNewPassword(
  createPasswordInputs: CreatePasswordInputs,
): Promise<ApiSuccess> {
  if (createPasswordInputs.passwordToken === '') {
    throw new Error('token must not be empty')
  }
  try {
    await Api.post('/auth/password', createPasswordInputs)
    return { status: 'success' }
  } catch (error) {
    throw error
  }
}
export async function getNewToken() {
  try {
    const refresh = await Api.post('/auth/refresh', {
      refreshToken: localStorage.getItem(clientStorageFields.refreshToken),
    })
    saveAuthToken(clientStorageFields.authToken, refresh.data.accessToken)
  } catch (error) {
    throw error
  }
}

export function logoutUser() {
  removeAuthToken(clientStorageFields.authToken)
  removeAuthToken(clientStorageFields.refreshToken)
}

export async function acceptDataCollection() {
  try {
    await Api.get('/users/me/accept-data-collection')
  } catch (error) {
    throw error
  }
}
