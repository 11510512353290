import { Button, makeStyles } from '@material-ui/core'
import React from 'react'
import { ChuTheme } from '../../@types/theme'

interface Props {
  title: string
  disabled?: boolean
  type?: 'button' | 'submit'
  onClick?: () => void
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    color: theme.palette.white,
    '&:disabled': {
      background: theme.palette.grey,
    },
  },
}))

/**
 * Bouton pour soumettre un formulaire
 */
export const SubmitButton: React.FC<Props> = ({
  title,
  disabled,
  type = 'submit',
  onClick,
}) => {
  const classes = useStyles()

  return (
    <Button
      variant="contained"
      color="secondary"
      size="large"
      type={type}
      onClick={onClick}
      className={classes.root}
      disabled={disabled}
    >
      {title}
    </Button>
  )
}
