export default {
  loading: {
    whileSigningIn: 'Chargement de votre compte',
  },
  sign: {
    login: {
      intro: "Bienvenue sur l'outil de suivi des patients",
      title: 'Merci de vous identifier',
      username: {
        label: 'Adresse email',
      },
      password: {
        label: 'Mot de passe',
      },
      forgottenPassword: {
        label: 'Réinitialiser le mot de passe',
      },
      button: 'Je me connecte',
    },
    forgottenPassword: {
      intro: "Bienvenue sur l'outil de suivi des patients",
      title: 'Réinitialiser le mot de passe',
      request: {
        errors: {
          title: 'Une erreur est survenue',
          message: 'Aucun utilisateur trouvé avec cette adresse e-mail',
        },
        email: {
          label: 'Adresse e-mail',
          email: "L'adresse e-mail est invalide",
          required: "L'e-mail est requis",
        },
        success: {
          title: "Succés de l'opération",
          message:
            'Un mail vient de vous être envoyé afin de poursuivre le processus',
        },
        button: 'Valider',
        return: 'retour',
      },
      reset: {
        errors: {
          title: 'Une erreur est survenue',
          message: "Le lien de n'est pas ou plus valide",
        },
        password: {
          label: 'Mot de passe',
          required: 'Le mot de passe est requis',
        },
        confirmPassword: {
          label: 'Confirmation du mot de passe',
          required: 'La confirmation du mot de passe est requise',
        },
        notTheSamePassword: 'les deux mots de passe doivent être identiques',
        button: 'Valider',
        return: 'retour',
      },
    },
    createPassword: {
      title: 'Créer un nouveau mot de passe',
      confirm: 'Je valide',
    },
  },
  survey: {
    buttons: {
      next: 'Je valide ma réponse',
      previous: 'Question précédente',
      return: 'Retour',
      returnToDescription: 'Revoir la consigne',
      cancelAnswers: 'Supprimer mes réponses',
      sendAnswers: "J'envoie mes réponses",
      submit: "Voir mes réponses avant d'envoyer",
      understood: "Ok, j'ai compris",
    },
  },
  underdevelopment: {
    label: 'Fonctionnalité en cours de développement',
  },
  appointment: {
    title: 'Votre prochain rendez-vous',
    date: 'RDV vend. 10/04 à 10h30 Dr P. TIBERGHIEN-BONTE',
    buttons: {
      completed: "Merci d'avoir pris le temps de remplir le questionnaire",
      startForm: 'Renseigner le questionnaire préparatoire au rdv',
    },
  },
  homepage: {
    welcome: 'Bienvenue',
    lastConnection: {
      lastConnection: 'Dernière connexion : le',
      dateFormat: 'dd/MM/yyyy à HH:mm',
    },
    timeSince: 'Vous êtes maintenant sorti de l’hôpital depuis',
    nbOfDays: '14 jours',
    button: {
      takeAppointment: 'Je demande une télé-consultation',
      appointmentTaken:
        'Une demande de rendez-vous a été envoyée à votre médecin',
    },
    emergency: 'Pour toutes urgences, appelez le 15',
    moodQuestion: 'Plus précisément, comment vous vous sentez aujourd’hui ?',
    needYou:
      'Nous avons besoin de vous pour effectuer le suivi de votre état de santé, à distance.',
    appointmentConfirmDialogTitle: 'Demande de télé-consultation',
    appointmentConfirmDialogContent:
      "Vous êtes sur le point de faire une demande de télé-consultation auprès d'un médecin, voulez-vous valider ?",
    appointmentConfirmDialogCancel: 'Annuler',
    appointmentConfirmDialogConfirm: 'Valider',
  },
  moodBox: {
    myMoodOfTheDay1: 'Mon humeur',
    myMoodOfTheDay2: 'du jour',
  },
  confirmForm: {
    title: 'Êtes-vous sûre ?',
    warningText:
      'Si vous supprimez vos précédentes réponses, vous devrez recommencer le questionnaire dès le début.',
    cardText: 'Cette action est irréversible',
    buttons: {
      cancel: 'annuler la suppression',
      confirm: 'oui, je confirme',
    },
  },
  formHomepage: {
    intro:
      ' Nous avons besoin de vous pour effectuer le suivi de votre état de santé, à distance.',
    letStart: 'Prenez votre temps, installez-vous au calme, confortablement',
  },
  formDescription: {
    desc:
      'Les questions qui suivent portent sur votre état de santé, telle que vous le ressentez. Veuillez répondre a toutes les questions en sélectionnant la réponse vous concernant. Si vous ne savez pas très bien comment répondre, choisissez la réponse la plus proche de votre situation.',
  },
  formResume: {
    resumeText:
      'Vous avez terminé le questionnaire. Voici le récapitulatif de vos réponses :',
  },
  endForm: {
    title:
      'Merci d’avoir pris le temps nécessaire à la préparation de votre prochain rendez-vous',
    text:
      'Les réponses au questionnaire ont bien été envoyé à l’équipe médicale. Prenez soin de vous !',
    buttons: {
      close: 'fermer',
    },
  },
  contactPage: {
    title: 'Vous souhaitez nous contacter ? Le motif concerne…',
    buttons: {
      appointment: 'je demande une télé-consultation',
      healthConcerns: 'Je pose des questions sur ma santé',
      appBugRequest: "Je déclare un problème sur l'outil de suivi",
    },
  },
  profilePage: {
    title: 'Votre fiche profil',
    lastNameLabel: 'Nom',
    firstNameLabel: 'Prénom',
    ageLabel: 'Âge',
    timeIn: 'Date d’hospitalisation',
    timeOut: 'Date de fin d’hospitalisation',
    totalTimeToHospital: 'Durée totale de l’hospitalisation',
    yourDoctors: 'Vos médecins',
    logout: 'Se déconnecter',
  },
  bugReportPage: {
    title: 'Je déclare un problème sur l’outil de suivi',
    question: 'Que souhaitez-vous partager avec nous, à propos de cet outil ?',
    page: 'Page concernée',
    bug: 'Type de problème rencontré',
    selectPage: [
      "Page d'authentification",
      "Page d'accueil",
      'Page profil',
      'Questionnaire',
      'Page contact',
    ],
    selectBug: [
      'Ca ne fonctionne pas',
      'Je ne trouve pas un renseignement',
      'Autre',
    ],
    describePb:
      'Décrivez en quelques lignes, le problème que vous avez rencontré au sein de cette page…',
    submitBug: "J'envoie ma remarque",
  },
  routeLeavingGuard: {
    titletext:
      'Vous souhaitez quitter ce questionnaire ? Vous ne pourrez pas poursuivre plus tard.',
    contentText:
      'En effet, il est important pour nous de recueillir vos réponses en une fois. Confirmez pour quitter, mais vous perdrez les réponses déjà remplies.',
    cancelText: 'je retourne aux questions',
    confirmText: 'je quitte le questionnaire',
  },
  calendar: {
    title:
      'Périodicité de vos 4 rendez-vous médicaux de suivi post-réanimation',
    outOfReanimation: 'Votre sortie de réanimation',
    title1Month: '1er',
    title3Months: '2e',
    title6Months: '3e',
    title1Year: '4e',
    toDo: 'Complétez au moins 1',
    before: 'avant le',
    completed: 'complété !',
  },
}
