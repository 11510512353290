import {
  Button,
  Card,
  CardContent,
  Container,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useContext } from 'react'
import { ChuTheme } from '../../../../@types/theme'
import { resetCurrentFormSurveyAndFormPostAction } from '../../../../context/actions/survey'
import { AppContext } from '../../../../context/app-context'
import browserHistory from '../../../../router/history'
import { settings } from '../../../../theme/settings'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    height: 'inherit',
    textAlign: 'center',
    paddingTop: '50px',
    overflow: 'auto',
  },
  card: {
    padding: '30px 15px',
    marginTop: '-45px',
    borderRadius: '10px',
  },
  cardTitle: {
    fontSize: '24px',
    color: theme.palette.primary.main,
    fontWeight: 600,
    lineHeight: 1.1,
    margin: '10px 0',
  },
  cardText: {
    fontSize: '18px',
    color: theme.palette.primary.main,
    lineHeight: 1.2,
  },
}))

export const SurveyEnd = () => {
  const classes = useStyles()
  const { dispatch } = useContext(AppContext)

  const handleClickOnEndForm = async () => {
    await dispatch(resetCurrentFormSurveyAndFormPostAction())
    browserHistory.replace('/')
  }

  return (
    <Container className={classes.root}>
      <img src={settings.images.confirmForm} alt="confirmation questionnaire" />
      <Card className={classes.card}>
        <CardContent>
          <Typography component="h2" className={classes.cardTitle}>
            {settings.strings.endForm.title}
          </Typography>
          <Typography component="h3" className={classes.cardText}>
            {settings.strings.endForm.text}
          </Typography>
        </CardContent>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleClickOnEndForm}
        >
          {settings.strings.endForm.buttons.close}
        </Button>
      </Card>
    </Container>
  )
}
