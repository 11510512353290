import Api from './api'

export async function getCalendars() {
  try {
    const response = await Api.get<ApiCalendar[]>('/forms/schedules')
    return response.data
  } catch (e) {
    throw new Error('could not get calendar')
  }
}
