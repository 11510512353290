import styled from '@emotion/styled'
import { Container, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { ChuTheme } from '../../@types/theme'
import { settings } from '../../theme/settings'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    height: 'inherit',
    color: theme.palette.white,
    textAlign: 'center',
    padding: '50px 30px 30px 30px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}))

const UnderDevelopmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  img {
    width: calc(100% - 8rem);
    max-height: 60vh;
    margin-bottom: 2rem;
  }
  p {
    color: white;
    font-size: 1.6rem;
    font-weight: 500; /*Roboto Medium */
    letter-spacing: 0.4rem;
    text-align: center;
    max-width: 39rem;
  }
`

export const UIUnderDevelopment = () => {
  const classes = useStyles()
  return (
    <Container className={classes.root}>
      <UnderDevelopmentContainer>
        <img src={settings.images.inProgress} alt="under development" />
        <Typography>
          {settings.strings.underdevelopment.label.toUpperCase()}
        </Typography>
      </UnderDevelopmentContainer>
    </Container>
  )
}
