import produce from 'immer'
import React from 'react'
import { MessageToast } from '../components/ui/MessageToast'
import { UILoading } from '../components/ui/UILoading'
import clientStorageFields from '../utils/clientStorageFields'
import { fetchUserInfosAction } from './actions/user'
import { createDispatch, DispatchAction, UpdateAction } from './condux'

interface LastSeenQuestion {
  question: Question
  currentStep: number
  currentNumberForStepper: number
  currentSubQuestionToSendToContext: false | any
}

interface AppContextState {
  // user
  user: LoadableData<AnyUser | undefined>
  patient: LoadableData<Patient | undefined>
  patientId: string | undefined
  authError?: string

  // mood
  lastMood: LoadableData<Mood | undefined>

  // nextAppointment
  nextAppointment: LoadableData<NextAppointment | undefined>

  // forms & surveys
  allForms: LoadableData<Form[] | undefined>
  currentForm?: Form

  // formPost
  arrayOfFormPostOfToday: LoadableData<FormPost[] | undefined>
  currentFormPost?: FormPost
  currentFormPostId?: string | null
  allSurveys: Array<Survey>
  currentSurvey?: Survey
  lastSeenQuestion?: LastSeenQuestion

  // calendar
  calendar: LoadableData<Calendar | undefined>

  // loading states
  createFormPostStatus: DataState
  takeAppointmentStatus: DataState
  sendFeedbackStatus: DataState
  sendUserSurveyStatus: DataState
  resetPasswordStatus: 'success' | 'error' | 'idle'
  createPasswordStatus: 'success' | 'error' | 'idle'

  // notifications
  notifications: {
    [key: string]: {
      style: any
      message: string
      isShown: boolean
      persist?: boolean
    }
  }

  // misc
  passwordUpdateModalOpen: boolean

  // dispatch
  dispatch: DispatchAction<AppContextState>
}

interface DefaultState extends Omit<AppContextState, 'dispatch'> {}

export const AppContext = React.createContext<AppContextState>(
  {} as AppContextState,
)

export const defaultState: DefaultState = {
  user: {
    data: undefined,
    state: 'idle',
  },
  patient: {
    data: undefined,
    state: 'idle',
  },
  patientId: undefined,
  lastMood: {
    data: undefined,
    state: 'idle',
  },
  allForms: {
    data: undefined,
    state: 'idle',
  },
  arrayOfFormPostOfToday: {
    data: undefined,
    state: 'idle',
  },
  calendar: {
    data: undefined,
    state: 'idle',
  },
  nextAppointment: {
    data: undefined,
    state: 'idle',
  },
  createFormPostStatus: 'idle',
  sendUserSurveyStatus: 'idle',
  takeAppointmentStatus: 'idle',
  sendFeedbackStatus: 'idle',
  allSurveys: [],
  notifications: {},
  resetPasswordStatus: 'idle',
  createPasswordStatus: 'idle',
  passwordUpdateModalOpen: false,
}

export class AppContextProvider extends React.PureComponent<
  {},
  AppContextState
> {
  constructor(props: {}) {
    super(props)

    this.state = {
      dispatch: createDispatch(() => this.state, this.setState.bind(this)),
      ...defaultState,
    }
  }

  componentDidMount() {
    // log user if token
    if (
      localStorage.getItem(clientStorageFields.authToken) ||
      localStorage.getItem(clientStorageFields.refreshToken)
    ) {
      this.state.dispatch(fetchUserInfosAction())
    }
  }

  public render() {
    const isLoading =
      localStorage.getItem(clientStorageFields.authToken) &&
      (this.state.user.state === 'loading' || this.state.user.state === 'idle')

    return (
      <AppContext.Provider value={this.state}>
        {isLoading ? (
          <UILoading />
        ) : (
          <>
            {Object.keys(this.state.notifications).map((notif, index) => {
              return (
                <MessageToast
                  key={`${notif}-${index}`}
                  id={notif}
                  onClose={() => this.onClose(notif)}
                  persist={this.state.notifications[notif]?.persist}
                />
              )
            })}
            {this.props.children}
          </>
        )}
      </AppContext.Provider>
    )
  }
  private onClose = (key: string) => {
    this.setState((prevState) =>
      produce(prevState, (draft) => {
        delete draft.notifications[key]
      }),
    )
  }
}

export type ContextAction = UpdateAction<AppContextState>

export const addNotificationAction = (
  key: string,
  style: string,
  message: string,
  persist?: boolean,
): ContextAction => async (produceState) =>
  produceState((draft) => {
    draft.notifications[key] = {
      isShown: true,
      style,
      message,
      persist,
    }
  })
