import * as CalendarApi from '../../services/calendar.service'
import { fromApiCalendar } from '../../utils/mappings'
import { ContextAction } from '../app-context'

export const getCalendarAction = (): ContextAction => async (produceState) => {
  await produceState((draft) => {
    draft.calendar.state = 'loading'
  })

  try {
    const calendars = await CalendarApi.getCalendars()
    const calendar = calendars.find(
      (item) => item._id === '5f058e03fcfd433cfa22714c',
    )
    await produceState((draft) => {
      draft.calendar.data = calendar
        ? fromApiCalendar(calendar, draft.patient.data?.activatedAt)
        : undefined
      draft.calendar.state = 'loaded'
    })
  } catch (e) {
    console.error(e)
    produceState((draft) => {
      draft.calendar.state = 'error'
    })
  }
}
