import fr from '../../assets/strings/fr'
import { requestAppointment } from '../../services/appointments.service'
import { addNotificationAction, ContextAction } from '../app-context'

export const takeAppointmentAction = (): ContextAction => async (
  produceState,
  getState,
) => {
  produceState((draft) => {
    draft.takeAppointmentStatus = 'loading'
  })

  try {
    await requestAppointment()

    await produceState((draft) => {
      draft.takeAppointmentStatus = 'loaded'
    })

    getState().dispatch(
      addNotificationAction(
        'consult-send',
        'success',
        fr.validationConsult.message,
      ),
    )
  } catch (error) {
    console.error(error)
    produceState((draft) => {
      draft.takeAppointmentStatus = 'error'
    })
    getState().dispatch(
      addNotificationAction(
        'consult-error',
        'error',
        fr.validationConsult.errorMessage,
      ),
    )
  }
}
