import { settings } from '../settings'

export default {
  root: {
    backgroundColor: `${settings.theme.palette.white}`,
    '&:hover': {
      backgroundColor: `${settings.theme.palette.white}`,
      '@media (hover: none)': {
        backgroundColor: `${settings.theme.palette.white}`,
      },
    },
    '&$focused': {
      '&$focused': {
        backgroundColor: `${settings.theme.palette.white}`,
      },
    },
    underline: {
      '&:hover:before': {
        borderBottom: `solid 1px ${settings.theme.palette.primary.light}`,
      },
      '&:after': {
        borderBottom: `solid 2px ${settings.theme.palette.primary.light}`,
      },
    },
  },
  input: {
    fontSize: '14px',
  },
}
