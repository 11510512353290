import React, { useContext, useState } from 'react'
import { userFirstConnectionAction } from '../../../context/actions/user'
import { AppContext } from '../../../context/app-context'
import { ResponsiveDialog } from '../../ui/ResponsiveDialog'
import { SwipeableStepper } from './SwipeableStepper'

export const Onboarding = () => {
  const { dispatch, user } = useContext(AppContext)
  const firstConnection = Boolean(user.data?.firstConnection)
  const [open, setOpen] = useState(firstConnection)

  const closeDialog = () => {
    setOpen(false)
    if (firstConnection) {
      dispatch(userFirstConnectionAction())
    }
  }

  return (
    <>
      <ResponsiveDialog open={open} onClose={closeDialog}>
        <SwipeableStepper closeDialog={closeDialog} />
      </ResponsiveDialog>
    </>
  )
}
