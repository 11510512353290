import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { ChuTheme } from '../../../@types/theme'
import { settings } from '../../../theme/settings'

const OPACITY = '33' // 20% in hex

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
  },
  outerBox: {
    width: '80%',
    backgroundColor: `${theme.palette.white}${OPACITY}`,
    padding: theme.spacing(2),
    borderRadius: 8,
    boxShadow: `3px 3px 6px ${theme.palette.black}${OPACITY}`,
  },
  innerBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.white,
    padding: theme.spacing(1.5),
    borderRadius: 8,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  nextAppointmentIcon: {
    width: 45,
    height: 45,
    marginRight: theme.spacing(1.5),
  },
  nextAppointmentText: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.info.main,
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  nextAppointmentDate: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main,
    textAlign: 'left',
    textTransform: 'uppercase',
  },
}))

/**
 * Bloc appelé dans <Home /> indiquant la date du prochain rendez-vous du patient
 */
export const NextAppointment = ({
  nextAppointment,
}: {
  nextAppointment: NextAppointment
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.outerBox}>
        <Box className={classes.innerBox}>
          <img
            className={classes.nextAppointmentIcon}
            src={settings.images.nextAppointmentIcon}
            alt=""
          />
          <Box className={classes.textContainer}>
            <Typography className={classes.nextAppointmentText}>
              Votre prochain rendez-vous
            </Typography>
            <Typography className={classes.nextAppointmentDate}>
              {nextAppointment.appointmentDate}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
