import { Box, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { ChuTheme } from '../../../@types/theme'

const BACKGROUND_OPACITY = '33'
const ARROW_BORDER_WIDTH = 8

interface Props {
  position: 'left' | 'right'
  header?: string
  body: string
  active?: boolean
}

const useStyles = makeStyles<ChuTheme, Props>((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: (props) => {
      const leftPosition = `calc(100%${props.header ? '' : ' + 16px'})`
      return props.position === 'right' ? leftPosition : undefined
    },
    right: (props) => {
      const rightPosition = `calc(100%${props.header ? '' : ' + 16px'})`
      return props.position === 'left' ? rightPosition : undefined
    },
    transform: 'translateY(-50%)',
    width: 'calc(35vw - 10px)',
    maxWidth: '120px',
    backgroundColor: (props) =>
      props.active
        ? theme.palette.white
        : `${theme.palette.white}${BACKGROUND_OPACITY}`,
    borderRadius: 8,

    // Flèche de la bulle d'info
    '&::after': {
      content: '" "',
      position: 'absolute',
      top: '50%',
      right: (props) => (props.position === 'right' ? '100%' : undefined),
      left: (props) => (props.position === 'left' ? '100%' : undefined),
      transform: `translateY(-${ARROW_BORDER_WIDTH}px)`,
      borderWidth: ARROW_BORDER_WIDTH,
      borderStyle: 'solid',
      borderColor: (props) => {
        const opacity = props.active ? '' : BACKGROUND_OPACITY
        return props.position === 'right'
          ? `transparent ${theme.palette.white}${opacity} transparent transparent`
          : `transparent transparent transparent ${theme.palette.white}${opacity}`
      },
    },
  },
  header: {
    borderRadius: '8px 8px 0 0',
    backgroundColor: theme.palette.primary.main,
    padding: 4,
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
  },
  text: {
    fontSize: 12,
    textAlign: 'center',
    color: theme.palette.white,
  },
  bodyText: {
    color: (props) => (props.active ? theme.palette.primary.main : undefined),
  },
}))

/**
 * Visuel type "tooptip" utilisé dans <Calendar /> pour afficher des informations
 * Il peut y avoir un header ou pas
 * Le contenu peut être "actif" (background blanc, "il faut compléter le questionnaire")
 * Ou non actif (background en transparence, "le questionnaire a été complété")
 */
export const CalendarInfo: React.FC<Props> = (props) => {
  const { header, body } = props
  const classes = useStyles(props)
  return (
    <Box className={classes.root}>
      {header && (
        <Box className={classes.header}>
          <Typography className={classes.text}>{header}</Typography>
        </Box>
      )}
      <Box className={classes.body}>
        <Typography className={clsx(classes.text, classes.bodyText)}>
          {body}
        </Typography>
      </Box>
    </Box>
  )
}
