import { Box, makeStyles, Typography } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { ChuTheme } from '../../../@types/theme'
import i18n from '../../../assets/strings'
import {
  acceptDataCollectionAction,
  logoutAction,
} from '../../../context/actions/user'
import { AppContext } from '../../../context/app-context'
import { PreviousButton } from '../../ui/PreviousButton'
import { ResponsiveDialog } from '../../ui/ResponsiveDialog'
import { SubmitButton } from '../../ui/SubmitButton'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: theme.spacing(2),
  },
  textContainer: {
    marginBottom: theme.spacing(4),
  },
  title: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: 19,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  description: {
    color: theme.palette.primary.dark,
    fontSize: 15,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  information: {
    fontSize: 12,
    textAlign: 'center',
    color: theme.palette.info.dark,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

/**
 * Modale demandant d'accepter l'utilisation anonyme des données à des fins statistiques
 * Refuser déconnecte l'utilisateur
 */
export const DataCollectionAcceptanceModal = () => {
  const classes = useStyles()
  const strings = i18n.dataCollectionAcceptanceModal

  const { user, dispatch } = useContext(AppContext)
  const [loading, setLoading] = useState<boolean>(false)

  const open = !user.data?.dataCollectionAccepted
  const handleClose = () => {}
  const handleRefuse = async () => {
    setLoading(true)
    await dispatch(logoutAction())
    setLoading(false)
  }
  const handleAccept = async () => {
    setLoading(true)
    await dispatch(acceptDataCollectionAction())
    setLoading(false)
  }

  return (
    <ResponsiveDialog open={open} maxWidth="sm" onClose={handleClose}>
      <Box className={classes.root}>
        {/* TEXT CONTAINER */}
        <Box className={classes.textContainer}>
          <Typography className={classes.title}>{strings.title}</Typography>
          <Typography className={classes.description}>
            {strings.description}
          </Typography>
          <Typography className={classes.information}>
            {strings.information}
          </Typography>
        </Box>

        {/* BUTTONS CONTAINER */}
        <Box className={classes.buttonsContainer}>
          <PreviousButton
            title={strings.refuse}
            disabled={loading}
            onClick={handleRefuse}
            marginRight
          />
          <SubmitButton
            type="button"
            title={strings.accept}
            disabled={loading}
            onClick={handleAccept}
          />
        </Box>
      </Box>
    </ResponsiveDialog>
  )
}
