import React, { useContext } from 'react'
import { Redirect, Router, Switch } from 'react-router-dom'
import { Calendar } from '../components/screens/Calendar/Calendar'
import { Contact } from '../components/screens/Contact/Contact'
import { CreatePassword } from '../components/screens/CreatePassword/CreatePassword'
import { ForgottenPassword } from '../components/screens/ForgottenPasword/ForgottenPassword'
import { Home } from '../components/screens/Home/Home'
import { InspectorWithoutPatient } from '../components/screens/InspectorWithoutPatient/InspectorWithoutPatient'
import { LegalNotice } from '../components/screens/LegalNotice/LegalNotice'
import { PageNotFound } from '../components/screens/PageNotFound/PageNotFound'
import { PatientId } from '../components/screens/PatientId/PatientId'
import { ProfilePage } from '../components/screens/Profile/Profile'
import { Sign } from '../components/screens/Sign/Sign'
import { Survey } from '../components/screens/Survey/Survey'
import { PageLayout } from '../components/ui/PageLayout'
import { UIUnderDevelopment } from '../components/ui/UIUnderDevelopment'
import { AppContext } from '../context/app-context'
import { CustomRoute } from './CustomRoute'
import browserHistory from './history'

const RootRouter = () => {
  const { user, patient } = useContext(AppContext)
  const isAuthenticated = user.state === 'loaded'

  return (
    <Router history={browserHistory}>
      <PageLayout isAuthenticated={isAuthenticated}>
        {/* keep sign Route above isAuthenticated redirection so that redirect can work */}
        {!isAuthenticated && (
          <Switch>
            <CustomRoute path="/sign" component={Sign} />
            <CustomRoute
              path="/set-password/:token"
              component={CreatePassword}
            />
            <CustomRoute
              path="/forgotten-password/:token"
              component={ForgottenPassword}
            />
            <CustomRoute
              path="/forgotten-password"
              component={ForgottenPassword}
            />
            <CustomRoute path="/patient/:patientId" component={PatientId} />
            <CustomRoute path="/legal-notice" component={LegalNotice} />
            <Redirect
              to={`/sign?url=${browserHistory.location.pathname || '/'}`}
            />
          </Switch>
        )}
        {isAuthenticated && (
          <Switch>
            <CustomRoute
              path="/patient/not-found"
              component={InspectorWithoutPatient}
            />

            <CustomRoute path="/patient/:patientId" component={PatientId} />
            {user.data?.role === 'inspector' && !patient.data && (
              <Redirect exact from="/" to="/patient/not-found" />
            )}

            <CustomRoute exact path="/" component={Home} />

            <CustomRoute path="/profile" component={ProfilePage} />

            <Redirect exact path="/form" to="/" />
            <CustomRoute path="/form/:surveyId" component={Survey} />

            <CustomRoute
              path="/calendar"
              component={Calendar}
              roles={['patient']}
            />

            <CustomRoute
              path="/infos"
              component={UIUnderDevelopment}
              roles={['patient']}
            />

            <CustomRoute path="/contact" component={Contact} />
            <CustomRoute path="/legal-notice" component={LegalNotice} />
            <CustomRoute path="/not-found" component={PageNotFound} />

            <Redirect
              from="/sign"
              to={`/${browserHistory.location.search.split('/')[1]}`}
            />
            <Redirect to="/not-found" />
          </Switch>
        )}
      </PageLayout>
    </Router>
  )
}

export default RootRouter
