import { isApiPatient } from '../utils/typeGuards'
import Api from './api'

export async function getPatient(patientId: string) {
  try {
    const response = await Api.get<ApiAnyUser>(`/users/${patientId}`)
    if (!isApiPatient(response.data)) {
      throw new Error(`User requested must be a patient`)
    }
    return response.data
  } catch (e) {
    throw new Error(`Could not get patient with id ${patientId}`)
  }
}
