import React, { useContext } from 'react'
import { AppContext } from '../../../context/app-context'
import browserHistory from '../../../router/history'
import { SignIn } from './SignIn/SignIn'

export const Sign = () => {
  const { user, allForms } = useContext(AppContext)
  if (user.state === 'loaded' && allForms) {
    browserHistory.push('/')
  }

  return <SignIn />
}
