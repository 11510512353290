import { Box, IconButton, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { ChuTheme } from '../../../@types/theme'
import { settings } from '../../../theme/settings'

interface Props {
  imgSrc: string
  imgAlt: string
  selected?: boolean
  disabled?: boolean
  onClick?: () => void
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    position: 'relative',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 'fit-content',
  },
  iconButton: {
    borderRadius: '100%',
    border: `3px solid ${theme.palette.white}`,
    padding: 0,
    boxShadow: `3px 3px 6px ${theme.palette.shadowColor}`,
  },
  selected: {
    borderColor: theme.palette.selectedColor,
    padding: 2,
  },
  image: {
    borderRadius: '100%',
  },
  selectedIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}))

/**
 * An icon button to send the mood of a patient
 */
export const CirleIcon = ({
  imgSrc,
  imgAlt,
  selected,
  disabled,
  onClick,
}: Props) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <IconButton
        className={clsx({
          [classes.iconButton]: true,
          [classes.selected]: selected,
        })}
        onClick={onClick}
        disabled={disabled}
      >
        <img src={imgSrc} alt={imgAlt} className={classes.image} />
      </IconButton>
      {selected && (
        <img
          src={settings.images.iconCheck}
          alt="Sélectionné"
          className={classes.selectedIcon}
        />
      )}
    </Box>
  )
}
