import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import { ChuTheme } from '../../@types/theme'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {},
}))

export const Layout: React.FC = ({ children }) => {
  const classes = useStyles()
  return <Box className={classes.root}>{children}</Box>
}
