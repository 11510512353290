import Api, { StatusCode } from './api'

export async function getForms(
  patientId?: string,
): Promise<Array<ApiFormWithSurvey>> {
  const queryParams = patientId ? `?patientId=${patientId}` : ''
  try {
    const forms = await Api.get<Array<ApiForm>>(`/forms${queryParams}`)
    return await Promise.all(
      forms.data.map<Promise<any>>(async (form) => {
        const response = await Api.get<Array<ApiSurvey>>(
          `/forms/${form._id}/questionnaires`,
        )
        return { survey: [...response.data], ...form }
      }),
    )
  } catch (error) {
    throw new Error('Could not get forms and surveys')
  }
}

export async function getFormPostOfToday(
  patientId?: string,
): Promise<Array<ApiFormPost>> {
  const queryParams = patientId ? `?patientId=${patientId}` : ''
  try {
    const response = await Api.get<Array<ApiFormPost>>(
      `/form-posts/today${queryParams}`,
    )
    return response.data
  } catch (error) {
    throw new Error('Could not get forms of the day')
  }
}

export async function createFormPost(
  formId: string,
  patientId?: string,
  inspectorId?: string,
): Promise<ApiFormPost> {
  try {
    const response = await Api.post<ApiFormPost>('/form-posts', {
      formId,
      patientId,
      inspectorId,
    })
    return response.data
  } catch (error) {
    throw new Error('Could not get form post')
  }
}

export async function sendSurvey(
  userAnswers: ApiFormPostUPDATE,
  formPostId: string,
) {
  try {
    const response = await Api.put<ApiFormPostUPDATE>(
      `/form-posts/${formPostId}`,
      userAnswers,
    )
    if (response.status === StatusCode.created) {
      return true
    }
  } catch (error) {
    throw new Error('Could not send survey')
  }
}
