import { Box, makeStyles, Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import { ChuTheme } from '../../../@types/theme'
import fr from '../../../assets/strings/fr'
import {
  displayPasswordUpdateModalAction,
  logoutAction,
} from '../../../context/actions/user'
import { AppContext } from '../../../context/app-context'
import browserHistory from '../../../router/history'
import { PreviousButton } from '../../ui/PreviousButton'
import { ResponsiveDialog } from '../../ui/ResponsiveDialog'
import { SubmitButton } from '../../ui/SubmitButton'

const useStyles = makeStyles((theme: ChuTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    padding: theme.spacing(2),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    maxWidth: '800px',
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 24,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  text: {
    fontSize: 16,
    color: theme.palette.info.dark,
    textAlign: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

/**
 * Modale s'affichant lorsqu'un utilisateur se connecte avec un mot de passe datant de plus de 90 jours
 * Propose de modifier le mot de passe (par sécurité)
 */
export const PasswordUpdateWarningModal = () => {
  const classes = useStyles()
  const { passwordUpdateModalOpen, dispatch } = useContext(AppContext)

  const handleClose = async () => {
    await dispatch(displayPasswordUpdateModalAction(false))
  }

  const toResetPassword = async () => {
    await dispatch(logoutAction())
    await dispatch(displayPasswordUpdateModalAction(false))
    browserHistory.push('/forgotten-password')
  }

  return (
    <ResponsiveDialog open={passwordUpdateModalOpen} onClose={handleClose}>
      <Box className={classes.root}>
        {/* TEXT */}
        <Box className={classes.textContainer}>
          <Typography component="h2" className={classes.title}>
            {fr.passwordUpdateWarningModal.title}
          </Typography>
          <Typography component="p" className={classes.text}>
            {fr.passwordUpdateWarningModal.text}
          </Typography>
        </Box>

        {/* BUTTONS */}
        <Box className={classes.buttonsContainer}>
          <PreviousButton
            title={fr.passwordUpdateWarningModal.ignore}
            onClick={handleClose}
            marginRight
          />
          <SubmitButton
            title={fr.passwordUpdateWarningModal.toUpdatePassword}
            type="submit"
            onClick={toResetPassword}
          />
        </Box>
      </Box>
    </ResponsiveDialog>
  )
}
