import { Dialog, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'

interface ResponsiveDialogProps {
  open: boolean
  maxWidth?: 'sm' | 'md'
  onClose: () => void
}

export const ResponsiveDialog: React.FC<ResponsiveDialogProps> = ({
  open,
  maxWidth = 'md',
  onClose,
  children,
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={maxWidth}
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      {children}
    </Dialog>
  )
}
