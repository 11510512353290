import React from 'react'
import { UILoading } from '../../ui/UILoading'

export const PageNotFound = () => (
  <div>
    <UILoading>
      <h3>Cette page n'existe pas</h3>
    </UILoading>
  </div>
)
