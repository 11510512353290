import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { useContext, useEffect, useState } from 'react'
import { ChuTheme } from '../../../@types/theme'
import { getNextAppointmentAction } from '../../../context/actions/appointment'
import { takeAppointmentAction } from '../../../context/actions/message'
import { SurveyStateEnum } from '../../../context/actions/survey'
import { AppContext } from '../../../context/app-context'
import browserHistory from '../../../router/history'
import { settings } from '../../../theme/settings'
import { DialogMessage } from '../../ui/DialogMessage'
import { CirleIcon } from './CirleIcon'
import { DataCollectionAcceptanceModal } from './DataCollectionAcceptanceModal'
import { HomeHeader } from './HomeHeader'
import { MoodBox } from './MoodBox'
import { NextAppointment } from './NextAppointment'
import { Onboarding } from './Onboarding'
import { PasswordUpdateWarningModal } from './PasswordUpdateWarningModal'

const FORMS_ID_PSY = {
  MyMorale: '5ecfbe6996ee745b3c2ae15c',
  MyFeelings: '5ecfbe6996ee745b3c2ae158',
}

const FORMS_ORDER_PSY = [FORMS_ID_PSY.MyMorale, FORMS_ID_PSY.MyFeelings]

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    flexGrow: 1,
    height: 'inherit',
    color: theme.palette.white,
    textAlign: 'center',
    padding: '50px 0 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '180px 0 30px ',
      overflow: 'scroll',
    },
  },

  moodBoxContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  urgency: {
    fontSize: '11px',
  },
  button: {
    margin: '10px 0',
    '&:disabled': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.light,
    },
    textTransform: 'uppercase',
  },
  forms: {
    margin: '1Opx',
  },
  surveyStatus: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.success.main,
    paddingLeft: '5px',
  },
  paper: {
    padding: 20,
    textAlign: 'center',
    borderRadius: 'unset',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down('sm')]: {
      color: '#fff',
      background: 'unset',
    },
  },
  backgroundMedia: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  gridListTitle: {
    padding: 0,
  },
  gridListTitleBar: {
    height: '100%',
    background: 'unset',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-start',
  },
  gridTitleWrap: {
    flexGrow: 0,
    marginTop: 26,
  },
  gridTitle: {
    marginLeft: theme.spacing(0),
  },
  gridActionIcon: {
    display: 'flex',
    flexFlow: 'row',
    '& a': {
      color: '#fff',
      fontSize: '13px',
    },
  },
  typo: {
    '& h2, p, span': {
      fontSize: 16,
    },
  },
}))

export const Home = () => {
  const strings = settings.strings.homepage
  const classes = useStyles()
  const theme = useTheme()
  const isSmallDevice = useMediaQuery(theme.breakpoints.up('sm'))

  const {
    dispatch,
    takeAppointmentStatus,
    user,
    patient,
    allForms,
    nextAppointment,
  } = useContext(AppContext)

  useEffect(() => {
    const getNextAppointment = async () => {
      await dispatch(getNextAppointmentAction())
    }
    getNextAppointment()
  }, [dispatch])

  const [isConfirmAppointmentOpen, setIsConfirmAppointmentOpen] = useState(
    false,
  )

  // Functions
  const handleSurveyClick = async (survey: Survey) => {
    if (survey.state === SurveyStateEnum.completed) {
      return
    }
    browserHistory.push(`/form/${survey.id}`)
  }

  const handleTakeAppointmentButtonClick = async () => {
    setIsConfirmAppointmentOpen(true)
  }

  const handleTakeAppointmentConfirm = async () => {
    await dispatch(takeAppointmentAction())
    setIsConfirmAppointmentOpen(false)
  }
  const handleTakeAppointmentCancel = async () => {
    setIsConfirmAppointmentOpen(false)
  }

  // Data
  const allFormsByThematic = {
    [settings.listThematic.FirstForm]:
      allForms.data?.filter(
        (form) => form.thematic.label === settings.listThematic.FirstForm,
      ) || [],
    [settings.listThematic.SecondForm]:
      allForms.data
        ?.filter(
          (form) => form.thematic.label === settings.listThematic.SecondForm,
        )
        .sort(
          (formA, formB) =>
            FORMS_ORDER_PSY.indexOf(formB.id) -
            FORMS_ORDER_PSY.indexOf(formA.id),
        ) || [],
    [settings.listThematic.ThirdForm]:
      allForms.data?.filter(
        (form) => form.thematic.label === settings.listThematic.ThirdForm,
      ) || [],
    [settings.listThematic.FourthForm]:
      allForms.data?.filter(
        (form) => form.thematic.label === settings.listThematic.FourthForm,
      ) || [],
  }

  return (
    <Container className={classes.root} maxWidth="lg">
      <Onboarding />
      <DataCollectionAcceptanceModal />
      <PasswordUpdateWarningModal />

      <HomeHeader patient={patient.data} />
      {nextAppointment.data && (
        <NextAppointment nextAppointment={nextAppointment.data} />
      )}

      <Grid container>
        {user.data?.role === 'patient' && (
          <Grid item xs={12} md={3}>
            <Box className={classes.moodBoxContainer}>
              <MoodBox />
            </Box>
          </Grid>
        )}
        <Grid item sm={12} md={user.data?.role === 'inspector' ? 12 : 9}>
          <Grid container>
            <GridList
              cellHeight={!isSmallDevice ? 180 : 200}
              style={{ margin: 0 }}
            >
              {user.data?.role === 'patient' && (
                <GridListTile cols={2} style={{ height: 'auto', padding: 0 }}>
                  <Paper className={classes.paper} elevation={0}>
                    {settings.strings.homepage.moodQuestion}
                  </Paper>
                </GridListTile>
              )}
              {Object.keys(allFormsByThematic).map((thematic) => (
                <GridListTile
                  key={thematic}
                  cols={!isSmallDevice ? 2 : 1}
                  style={{ padding: 0 }}
                >
                  <BackgroundCard
                    img={allFormsByThematic[thematic][0]?.thematic?.background}
                    className={classes.backgroundMedia}
                  />
                  <GridListTileBar
                    title={
                      <Typography variant="h4" style={{ fontWeight: 900 }}>
                        {thematic.toUpperCase()}
                      </Typography>
                    }
                    classes={{
                      root: classes.gridListTitleBar,
                      titleWrap: classes.gridTitleWrap,
                      title: classes.gridTitle,
                      actionIcon: classes.gridActionIcon,
                    }}
                    actionIcon={
                      <>
                        {allFormsByThematic[thematic].map(
                          (form) =>
                            form.surveys[0] && (
                              <ContentCard
                                key={form.id}
                                form={form}
                                img={form.icon}
                                onClick={() =>
                                  handleSurveyClick(form.surveys[0])
                                }
                                selected={form.state === 'completed'}
                              />
                            ),
                        )}
                      </>
                    }
                  />
                </GridListTile>
              ))}
            </GridList>
          </Grid>
        </Grid>
      </Grid>

      {user.data?.role === 'patient' && (
        <Box mt={6}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
            onClick={handleTakeAppointmentButtonClick}
            disabled={takeAppointmentStatus === 'loaded'}
          >
            {takeAppointmentStatus === 'loaded'
              ? strings.button.appointmentTaken
              : strings.button.takeAppointment}
          </Button>
          <Typography className={classes.urgency}>
            {strings.emergency}
          </Typography>
        </Box>
      )}

      {/* CONFIRMATION DE DEMANDE DE TELE-CONSULTATION */}
      <DialogMessage
        open={isConfirmAppointmentOpen}
        title={strings.appointmentConfirmDialogTitle}
        content={strings.appointmentConfirmDialogContent}
        cancel={strings.appointmentConfirmDialogCancel}
        confirm={strings.appointmentConfirmDialogConfirm}
        onConfirm={handleTakeAppointmentConfirm}
        onClose={handleTakeAppointmentCancel}
        disabled={takeAppointmentStatus === 'loading'}
      />
    </Container>
  )
}

export const BackgroundCard = ({
  img,
  className,
}: {
  img: string
  className: string
}) => {
  const link = `${settings.imgPath}/png/${img}`

  return <img src={link} className={className} alt="" />
}

export const IconBox = ({
  img,
  selected,
  imgAlt,
}: {
  img: string
  selected?: boolean
  imgAlt: string
}) => {
  const link = `${settings.imgPath}/svg/HP_Questionnaire_${img}.svg`

  return <CirleIcon imgSrc={link} imgAlt={imgAlt} selected={selected} />
}

const ContentCard = ({
  onClick,
  img,
  form,
  selected,
}: {
  onClick: () => void
  img: string
  form: Form
  selected?: boolean
}) => {
  return (
    <Box mt={2} ml={1} key={form.id} width={90}>
      <Link variant="button" onClick={onClick}>
        <Box display="flex" justifyContent="center" mb={1}>
          <IconBox img={img} selected={selected} imgAlt={form.name} />
        </Box>
        <Box lineHeight="15px" style={{ textTransform: 'none' }}>
          {form.name}
        </Box>
      </Link>
    </Box>
  )
}
