import * as AppointmentApi from '../../services/appointments.service'
import { fromApiNextAppointment } from '../../utils/mappings'
import { ContextAction } from '../app-context'

export const getNextAppointmentAction = (): ContextAction => async (
  produceState,
) => {
  await produceState((draft) => {
    draft.nextAppointment.state = 'loading'
  })
  try {
    const nextAppointment = await AppointmentApi.getNextAppointment()

    produceState((draft) => {
      draft.nextAppointment.data = fromApiNextAppointment(nextAppointment)
      draft.nextAppointment.state = 'loaded'
    })
  } catch (e) {
    produceState((draft) => {
      draft.nextAppointment.state = 'error'
    })
    console.error(e)
  }
}
