import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { ChuTheme } from '../../../@types/theme'

interface LegalNoticeSectionProps {
  title: string
  contents: Array<string>
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  content: {
    fontSize: 14,
  },
}))

export const LegalNoticeSection: React.FC<LegalNoticeSectionProps> = ({
  title,
  contents,
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
      {contents.map((content) => (
        <Typography className={classes.content}>{content}</Typography>
      ))}
    </Box>
  )
}
