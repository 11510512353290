import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { ChuTheme } from '../../../@types/theme'
import i18n from '../../../assets/strings'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'inherit',
  },
  mainContainer: {
    padding: 16,
  },
  titleContainer: {
    marginBottom: 24,
  },
  title: {
    fontSize: 32,
    fontWeight: 'bold',
    color: theme.palette.white,
    textAlign: 'center',
  },
  description: {
    fontSize: 16,
    color: theme.palette.white,
    textAlign: 'center',
  },
}))

/**
 * Page vers laquelle un utilisateur est redirigé lorsqu'il a un rôle inspector et qu'il essaie d'afficher <Home />
 * alors qu'aucun patient n'est enregistré dans le contexte
 */
export const InspectorWithoutPatient = () => {
  const strings = i18n.inspectorWithoutPatient
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.mainContainer}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title}>{strings.title}</Typography>
        </Box>
        <Box>
          <Typography className={classes.description}>
            {strings.description}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
