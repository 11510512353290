import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import { ChuTheme } from '../../../@types/theme'
import fr from '../../../assets/strings/fr'
import { settings } from '../../../theme/settings'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    textAlign: 'center',
    position: 'relative',
    '& h2': {
      fontSize: 24,
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '28px',
      marginTop: theme.spacing(6),
    },
    '& p': {
      fontSize: 18,
      color: theme.palette.primary.dark,
    },
  },
  icon: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '-130px',
  },
  dialogContainer: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
  },
  dialogInner: {
    maxWidth: 500,
    margin: 'auto',
  },
  actionBar: {
    justifyContent: 'center',
    flexFlow: 'column',
    padding: '40px 25px',
  },
  closeButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.success.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}))

export const ValidationDialog = ({
  open,
  onClose,
  onClick,
}: {
  open: boolean
  onClose: () => void
  onClick: any
}) => {
  const classes = useStyles()
  const [fullWidth] = useState(true)
  const [maxWidth] = useState<DialogProps['maxWidth']>('md')

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          position: 'relative',
          overflowY: 'visible',
        },
      }}
      classes={{ root: classes.root }}
    >
      <Box display="flex" justifyContent="center" className={classes.icon}>
        <img src={settings.images.confirmForm} alt="confirmation" />
      </Box>
      <Box className={classes.dialogContainer}>
        <Box className={classes.dialogInner}>
          <DialogTitle id="customized-dialog-title">
            {fr.validationDialog.title}
          </DialogTitle>
          <DialogContent>
            <Typography>{fr.validationDialog.content}</Typography>
          </DialogContent>
        </Box>
        <DialogActions className={classes.actionBar}>
          <Button
            variant="contained"
            size="large"
            className={classes.closeButton}
            onClick={onClick}
          >
            {fr.endForm.buttons.close}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
