import {
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { Formik } from 'formik'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import fr from '../../../assets/strings/fr'
import { resetPasswordAction } from '../../../context/actions/user'
import { AppContext } from '../../../context/app-context'
import { settings } from '../../../theme/settings'
import { AuthFormLayout } from '../../ui/AuthFormLayout'
import { PreviousButton } from '../../ui/PreviousButton'
import { SubmitButton } from '../../ui/SubmitButton'

interface Props {
  token: string
}

const schema = Yup.object().shape({
  password: Yup.string().required(
    fr.sign.forgottenPassword.reset.password.required,
  ),
  confirmPassword: Yup.string()
    .required(fr.sign.forgottenPassword.reset.confirmPassword.required)
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        fr.sign.forgottenPassword.reset.notTheSamePassword,
      ),
    }),
})

/**
 * Formulaire pour reset son mot de passe (mdp + confirmation du mot de passe)
 */
export const ForgottenPasswordReset: React.FunctionComponent<Props> = ({
  token,
}) => {
  const browserHistory = useHistory()

  const { dispatch } = useContext(AppContext)

  const [showError, setShowError] = useState(false)
  const [displayPassword, setDisplayPassword] = useState(false)
  const [displayConfirmPassword, setDisplayConfirmPassword] = useState(false)

  const goBack = () => {
    browserHistory.push('/')
  }

  const handleClickShowPassword = () => {
    setDisplayPassword((val) => !val)
  }
  const handleClickShowConfirmPassword = () => {
    setDisplayConfirmPassword((val) => !val)
  }

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  return (
    <AuthFormLayout
      header={settings.strings.sign.forgottenPassword.intro}
      title={settings.strings.sign.forgottenPassword.title}
      subtitle={fr.sign.forgottenPassword.subtitle}
      errorTitle={fr.sign.forgottenPassword.reset.errors.title}
      errorMessage={fr.sign.forgottenPassword.reset.errors.message}
      isError={showError}
      hideError={() => setShowError(false)}
    >
      <Formik<{ password: string; confirmPassword: string }>
        initialValues={{ password: '', confirmPassword: '' }}
        validationSchema={schema}
        onSubmit={async ({ password, confirmPassword }, { setSubmitting }) => {
          try {
            const resetPasswordInputs: ResetPasswordInputs = {
              token,
              newPassword: password,
              confirmNewPassword: confirmPassword,
            }
            await dispatch(resetPasswordAction(resetPasswordInputs))
            browserHistory.push('/')
          } catch (error) {
            setShowError(true)
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {({
          values,
          errors,
          dirty,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormControl variant="filled">
              <InputLabel htmlFor="filled-adornment-password">
                {settings.strings.sign.forgottenPassword.reset.password.label}
              </InputLabel>
              <FilledInput
                placeholder={
                  settings.strings.sign.forgottenPassword.reset.password.label
                }
                value={values.password}
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                type={displayPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      color="secondary"
                    >
                      {displayPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.password && (
                <FormHelperText>{errors.password}</FormHelperText>
              )}
            </FormControl>

            <FormControl variant="filled">
              <InputLabel htmlFor="filled-adornment-password">
                {
                  settings.strings.sign.forgottenPassword.reset.confirmPassword
                    .label
                }
              </InputLabel>
              <FilledInput
                placeholder={
                  settings.strings.sign.forgottenPassword.reset.confirmPassword
                    .label
                }
                value={values.confirmPassword}
                name="confirmPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                type={displayConfirmPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      color="secondary"
                    >
                      {displayConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.confirmPassword && (
                <FormHelperText>{errors.confirmPassword}</FormHelperText>
              )}
            </FormControl>

            <PreviousButton
              title={settings.strings.sign.forgottenPassword.reset.return}
              onClick={goBack}
              marginRight
            />
            <SubmitButton
              title={settings.strings.sign.forgottenPassword.reset.button}
              disabled={
                !dirty ||
                !!errors.password ||
                !!errors.confirmPassword ||
                isSubmitting
              }
            />
          </form>
        )}
      </Formik>
    </AuthFormLayout>
  )
}
