import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { ChuTheme } from '../../../../@types/theme'
import { AppContext } from '../../../../context/app-context'
import { settings } from '../../../../theme/settings'
import { TextInput } from '../../../ui/TextInput'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    padding: 0,
    position: 'relative',
    overflowY: 'hidden',
  },
  button: {
    margin: '5px',
  },
  question: {
    fontSize: '18px',
    textAlign: 'center',
    fontWeight: 500,
    color: theme.palette.white,
    lineHeight: 1.2,
    padding: '20px',
    backgroundColor: theme.palette.primary.main,
  },
  subquestion: {
    fontSize: '16px',
    textAlign: 'center',
    color: theme.palette.info.dark,
    lineHeight: 1.2,
  },
  formLabel: {
    minHeight: '40px',
    backgroundColor: theme.palette.background.paper,
    borderBottom: `solid 1px ${theme.palette.lightAccentColor}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
  },
  formControlLabelWrapper: {
    minHeight: '80px',
    borderBottom: `solid 1px ${theme.palette.lightAccentColor}`,
    padding: 0,
    paddingLeft: '20px',
    marginLeft: 0,
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    height: '90px',
    padding: '15px',
  },
}))

interface QAMultipleChoiceProps {
  question: Question
  handleChange: (arrayOfAnswers: Array<Answer>) => void
  nextButtonIsDisabled: boolean
  onNextButtonClick: () => void
  nextButtonLabel: string
}

export const QAMultipleChoice = ({
  question,
  handleChange,
  nextButtonIsDisabled,
  onNextButtonClick,
  nextButtonLabel,
}: QAMultipleChoiceProps) => {
  const classes = useStyles()
  const [checkedAnswers, setCheckedAnswers] = useState<Array<Answer>>(
    question.userAnswers,
  )
  const [text, setText] = useState<string>('')

  // when switching to another question, reset checkedAnswers (current component answers) and userAnswers (higher level answers)
  useEffect(() => {
    setCheckedAnswers(question.userAnswers)
  }, [question])

  // when answers are checked, send them to the parent so it can update context
  useEffect(() => {
    if (checkedAnswers && checkedAnswers.length > 0) {
      handleChange(checkedAnswers)
    }
  }, [checkedAnswers])

  // Coche ou décoche une réponses
  const handleClickOnCheckbox = (boxClicked: Answer, isBoxChecked: boolean) => {
    // 1. Si l'utilisateur a coché une réponse
    if (isBoxChecked) {
      // 1.2 Si la réponse est déjà cochée, ne fait rien
      if (checkedAnswers.includes(boxClicked)) {
        return
      }

      // 1.2 La réponse n'est pas cochée, ajoute
      setCheckedAnswers((checkedAnswers) =>
        checkedAnswers
          .filter(
            // Si il s'agit d'un champ libre, supprime l'ancienne valeur du champ libre
            (checkedAnswer) =>
              !(
                typeof boxClicked.value === 'string' &&
                typeof checkedAnswer.value === 'string'
              ),
          )
          .concat(boxClicked),
      )
    }

    // 2. Si l'utilisateur a décoché une réponse
    if (!isBoxChecked) {
      setCheckedAnswers((checkedAnswers) =>
        checkedAnswers.filter((item) => item.value !== boxClicked.value),
      )
    }
  }

  const handleNextButtonClick = () => {
    // if user has not selected an answer, prevent going forward
    if (!checkedAnswers || (checkedAnswers && checkedAnswers.length < 1)) {
      return
    }
    setText('')
    onNextButtonClick()
  }

  return (
    <Container className={classes.root}>
      <Typography component="h3" className={classes.question}>
        {question?.name} <br /> Plusieurs réponses possibles
      </Typography>

      <FormControl component="fieldset">
        <FormGroup>
          {question.isSubQuestion && (
            <FormLabel component="legend" className={classes.formLabel}>
              <Typography className={classes.subquestion}>
                {question.isSubQuestion && question.name}
              </Typography>
            </FormLabel>
          )}
          {question.answers.map((answer: Answer, index: number) => {
            const isChecked = checkedAnswers.some(
              (answ) =>
                answ.value === answer.value ||
                (typeof answ.value === 'string' &&
                  typeof answer.value === 'string'), // Cas de la question à champ libre
            )

            return (
              <FormControlLabel
                key={answer.name + index.toString()}
                style={
                  isChecked
                    ? {
                        backgroundColor: settings.theme.palette.success.light,
                        color: settings.theme.palette.success.main,
                        fontWeight: 500,
                      }
                    : {}
                }
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={() => {
                      handleClickOnCheckbox(
                        typeof answer.value === 'string'
                          ? { ...answer, value: text }
                          : answer,
                        !isChecked,
                      )
                    }}
                    name={answer.name}
                    color="primary"
                    classes={{
                      root: classes.formControlLabelWrapper,
                    }}
                  />
                }
                label={
                  typeof answer.value === 'string' ? (
                    <TextInput
                      label={answer.name}
                      value={text}
                      name={answer.name}
                      onFocus={() => {
                        const textAnswer: Answer = { ...answer, value: text }
                        handleClickOnCheckbox(textAnswer, true)
                      }}
                      onChange={(e) => {
                        const textAnswer: Answer = {
                          ...answer,
                          value: e.target.value,
                        }
                        handleClickOnCheckbox(textAnswer, true)
                        setText(e.target.value)
                      }}
                    />
                  ) : (
                    answer.name
                  )
                }
                value={answer.value}
              />
            )
          })}
        </FormGroup>

        <Box className={classes.inputWrapper}>
          <Button
            disabled={
              nextButtonIsDisabled ||
              checkedAnswers.length === 0 ||
              (checkedAnswers.length === 1 && checkedAnswers[0].value === '')
            }
            onClick={handleNextButtonClick}
            variant="contained"
            color="secondary"
            size="large"
            className={classes.button}
          >
            {nextButtonLabel}
          </Button>
        </Box>
      </FormControl>
    </Container>
  )
}
