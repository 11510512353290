import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { BugReportPage } from './BugReport'
import { ContactHomepage } from './ContactHomepage'

export const Contact: React.FC<any> = ({ match }) => {
  return (
    <Switch>
      <Route path={match.url + '/bug-report'} component={BugReportPage} />
      <Route exact path={match.url} component={ContactHomepage} />
    </Switch>
  )
}
