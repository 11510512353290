import { settings } from '../settings'

export default {
  root: {
    minHeight: '80px',
    borderBottom: `solid 1px ${settings.theme.palette.lightAccentColor}`,
    paddingLeft: '20px',
    marginRight: 0,
    paddingRight: '16px',
  },
  label: {
    fontSize: '18px',
    padding: '10px 4px 10px 10px ',
  },
}
