import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getSurveysAction } from '../../../context/actions/survey'
import {
  loadPatientAction,
  savePatientIdAction,
} from '../../../context/actions/user'
import { AppContext } from '../../../context/app-context'
import browserHistory from '../../../router/history'

/**
 * Composant appelé sur la route /patient/:patientId
 * Ce composant affiche un chargement pendant que patientId est stocké dans le context et que les données requises sont récupérées, puis redirige vers "/"
 */
export const PatientId = () => {
  const { patientId } = useParams<{ patientId?: string }>()
  const { dispatch, user } = useContext(AppContext)

  useEffect(() => {
    if (!patientId) {
      return
    }

    const initialize = async () => {
      await dispatch(savePatientIdAction(patientId))

      if (user.state === 'loaded') {
        await dispatch(loadPatientAction())
        await dispatch(getSurveysAction(patientId))
        browserHistory.push('/')
      } else {
        browserHistory.push('/sign')
      }
    }

    initialize()
  }, [dispatch, patientId])

  return <div />
}
