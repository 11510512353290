import { settings } from '../settings'

export default {
  root: {
    '&$checked': {
      color: `${settings.theme.palette.success.main}`,
    },
  },
  checked: {},
}
