import React, { useContext, useEffect, useState } from 'react'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'
import {
  getFormPostAction,
  updateCurrentFormAndSurveyAction,
} from '../../../context/actions/survey'
import { AppContext } from '../../../context/app-context'
import browserHistory from '../../../router/history'
import { settings } from '../../../theme/settings'
import { RouteLeavingGuard } from '../../ui/RouteLeavingGuard'
import { UIQA } from './QA/QA'
import { SurveyDescription } from './SurveyDescription/SurveyDescription'
import { SurveyEnd } from './SurveyEnd/SurveyEnd'
import { SurveyHomePage } from './SurveyHomePage/SurveyHomePage'

export const Survey: React.FC<any> = ({ match }) => {
  const { surveyId } = useParams<{ surveyId: string }>()
  const {
    dispatch,
    allSurveys,
    allForms,
    currentForm,
    currentSurvey,
    currentFormPost,
  } = useContext(AppContext)

  const [whenState] = useState(true)

  // Get survey and form using surveyId param
  useEffect(() => {
    const initialize = async () => {
      const survey = allSurveys.find((survey) => survey.id === surveyId)
      const form = allForms.data?.find((form) =>
        form.surveys.some((item) => item.id === surveyId),
      )
      if (!survey || !form) {
        return
      }
      await dispatch(updateCurrentFormAndSurveyAction(survey, form))
    }
    initialize()
  }, [
    dispatch,
    allSurveys,
    allForms,
    updateCurrentFormAndSurveyAction,
    surveyId,
  ])

  // Get formPost
  useEffect(() => {
    const initialize = async () => {
      if (!currentForm?.formPostId) {
        return
      }
      await dispatch(getFormPostAction(currentForm.formPostId))
    }
    initialize()
  }, [dispatch, currentForm?.formPostId, getFormPostAction])

  // Check if previous surveys have been completed (e.g. in cas user directly visits the url of the 2nd survey)
  const areAllPreviousSurveysCompleted = (form: Form, survey: Survey) => {
    for (let i = 0; i < form.surveys.length; i++) {
      if (form.surveys[i].id === survey.id) {
        break
      }

      const missingAnswer = form.surveys[i].questions.some(
        (question) => question.userAnswers.length === 0,
      )
      if (missingAnswer) {
        return false
      }
    }
    return true
  }

  const redirectToFirstForm =
    currentForm &&
    currentSurvey &&
    !areAllPreviousSurveysCompleted(currentForm, currentSurvey)

  return (
    <Switch>
      {currentFormPost?.state === 'completed' && <Redirect to="/" />}

      <Route path={`/form/:surveyId/end`} component={SurveyEnd} />

      {redirectToFirstForm &&
        currentForm?.surveys &&
        currentForm.surveys.length > 0 && (
          <Redirect to={`/form/${currentForm.surveys[0].id}`} />
        )}

      <Route exact path={match.url} component={SurveyHomePage} />

      <Route
        path={`/form/:surveyId/intro`}
        component={() => (
          <SurveyDescription description={currentSurvey?.description || ''} />
        )}
      />

      {currentSurvey && currentForm && (
        <>
          <Route
            path={`/form/:surveyId/qa`}
            render={() => (
              <UIQA survey={currentSurvey} currentForm={currentForm} />
            )}
          />
          <RouteLeavingGuard
            when={whenState}
            navigate={(path: any) => {
              browserHistory.push(path)
            }}
            shouldBlockNavigation={() => {
              if (whenState) {
                return true
              }
              return false
            }}
            titleText={settings.strings.routeLeavingGuard.titletext}
            contentText={settings.strings.routeLeavingGuard.contentText}
            cancelText={settings.strings.routeLeavingGuard.cancelText}
            confirmText={settings.strings.routeLeavingGuard.confirmText}
          />
        </>
      )}

      <Route component={SurveyHomePage} />
    </Switch>
  )
}
