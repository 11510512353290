import Axios, { AxiosInstance } from 'axios'
import browserHistory from '../router/history'
import clientStorageFields from '../utils/clientStorageFields'
import { getNewToken, removeAuthToken } from './auth.service'

export enum StatusCode {
  ok = 200,
  created = 201,
  unauthorized = 401,
  forbidden = 403,
  notFound = 404,
  internalError = 500,
}

export const setTokenHeader = () => {
  const authToken = localStorage.getItem(clientStorageFields.authToken)
  return (authToken && { Authorization: `Bearer ${authToken}` }) || {}
}

export const removeTokenHeader = () => {
  Api.defaults.headers.common['Authorization'] = undefined
}

const Api: AxiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

// at every request, set token stored in client storage
Api.interceptors.request.use(async (value) => {
  value.headers = { ...value.headers, ...setTokenHeader() }
  return value
})

Api.interceptors.response.use(
  (value) => Promise.resolve(value),
  async (error) => {
    if (
      error.response?.status === 401 &&
      error.config.url !== '/auth/refresh'
    ) {
      try {
        await getNewToken()
        // New request with new token
        const config = error.config
        const token = localStorage.getItem(clientStorageFields.authToken)
        config.headers['Authorization'] = `Bearer ${token}`

        return new Promise((resolve, reject) => {
          Axios.request(config)
            .then((response) => {
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      } catch (error) {
        if (error.response?.status === 401) {
          removeAuthToken(clientStorageFields.authToken)
          removeAuthToken(clientStorageFields.refreshToken)
          if (
            window.location.pathname !== '/sign' &&
            window.location.pathname.split('/')[1] !== 'forgotten-password'
          ) {
            browserHistory.push('/sign?url=' + window.location.pathname)
          }
        }
      }
    }
    return Promise.reject(error)
  },
)

export default Api
