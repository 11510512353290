import React, { useContext, useEffect, useRef, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { resetCurrentFormSurveyAndFormPostAction } from '../../context/actions/survey'
import { AppContext } from '../../context/app-context'
import { DialogMessage } from './DialogMessage'

export const RouteLeavingGuard = ({
  navigate,
  when,
  shouldBlockNavigation,
  titleText,
  contentText,
  cancelText,
  confirmText,
}: {
  titleText: string
  contentText: string
  cancelText: string
  confirmText: string
  navigate: (path: string) => void
  when?: boolean | undefined
  shouldBlockNavigation: (location: Location) => boolean
}) => {
  const [modalVisible, updateModalVisible] = useState(false)
  const [lastLocation, updateLastLocation] = useState<Location | null>(null)
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false)
  const { dispatch } = useContext(AppContext)
  const showModal = (location: any) => {
    updateModalVisible(true)
    updateLastLocation(location)
  }

  const closeModal = (cb: any) => {
    updateModalVisible(false)
    if (cb) {
      cb()
    }
  }

  const handleBlockedNavigation = (nextLocation: any, action: string) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      if (
        (!nextLocation.pathname.endsWith('/end') &&
          !nextLocation.pathname.endsWith('/intro')) ||
        action === 'POP'
      ) {
        showModal(nextLocation)
        return false
      }
    }
    return true
  }

  const handleConfirmNavigationClick = () => {
    closeModal(() => {
      if (lastLocation) {
        updateConfirmedNavigation(true)
      }
    })
  }

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname)
      updateConfirmedNavigation(false)
      if (!lastLocation.pathname.startsWith('/form')) {
        dispatch(resetCurrentFormSurveyAndFormPostAction())
      }
    }
  }, [dispatch, confirmedNavigation, navigate, lastLocation])

  //Affiche la dialog du navigateur lors du refresh ou de la fermeture du navigateur
  const useUnload = (fn: any) => {
    const cb = useRef(fn)
    useEffect(() => {
      const onUnload = cb.current
      window.addEventListener('beforeunload', onUnload)
      return () => window.removeEventListener('beforeunload', onUnload)
    }, [cb])
  }

  useUnload((e: any) => {
    e.preventDefault()
    e.returnValue = ''
  })

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <DialogMessage
        open={modalVisible}
        title={titleText}
        content={contentText}
        cancel={cancelText}
        confirm={confirmText}
        onConfirm={handleConfirmNavigationClick}
        onClose={closeModal}
      />
    </>
  )
}
