import { createMuiTheme } from '@material-ui/core/styles'
import overrides from './overrides'
import { settings } from './settings'

const palette = settings.theme.palette

const theme = createMuiTheme({
  palette: palette,
  overrides,
})
export default theme
