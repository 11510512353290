import { settings } from '../settings'

export default {
  root: {
    '&$focused': {
      '&$focused': {
        color: `${settings.theme.palette.primary.light}`,
      },
    },
  },
}
